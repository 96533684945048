/* Slider avec slide affichée 1 par 1 */
.single-slider:not(.sliderhome):not(.home-temoignage),
.single-slider:not(.sliderhome):not(.home-temoignage) .slide img,
.noslideshow:not(.sliderhome):not(.home-temoignage),
.noslideshow:not(.sliderhome):not(.home-temoignage) .slide img {
  /*height: 50rem;*/
}

.single-slider,
.noslideshow {
  padding-left: 0;
  margin-bottom: 0;

  .slide {
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
    }

    .islide-content {
      @include absolute-container;
      @include flex-center;

      .islide {
        width: 45%;
        padding: 4rem;
        text-align: center;
        background-color: rgba(255, 255, 255, .5);

        .h1 {
          margin-bottom: 1.5rem;
        }

        .h3 {
          margin-bottom: 3rem;
          @media (max-width: $breakpoint-xxl) {
            margin-bottom: 1rem;
          }
        }

        .button {
          cursor: pointer;

          &:hover {
            /*color: $color8;
            background-color: $color1;*/
          }
        }
      }
    }
  }

  .slick-prev {
    left: 3.5rem;
  }

  .slick-next {
    right: 3.5rem;
  }

  .slick-next::before,
  .slick-prev::before {
    color: $color9;
  }

  .slick-dots {
    bottom: 1.5rem;

    li button::before {
      background-color: $color9;
    }
  }
}

/* Slider avec plusieurs slides affichées */
.multi-slider {
  margin-left: -1rem;
  margin-right: -1rem;

  .slick-slide {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;

    .islide-content {
      padding: .5rem 1rem 1rem;

      .desc {
        height: 7rem;
        overflow: hidden;
      }
    }
  }

  .slick-prev {
    left: -4rem;
  }

  .slick-next {
    right: -4rem;
  }
}

/* Slider avec des vignettes (page article et news) */

.slider-single,
.slider-single-news {
  .slider-nav-container {
    position: relative;
    .slider-nav-content,
    figure {
      @include absolute-container;
      @include flex-center;
      cursor: pointer;
    }
  }
}

.slider-nav,
.slider-nav-news {
  .slick-track {
    margin: 0 1rem 1rem;

    .slick-slide {
      margin-right: 1.5rem;

      img {
        object-fit: cover;
        /*@include box-shadow-light;*/
        cursor: pointer;
        /*height: 25.3rem;*/
        border: .2rem solid transparent;
        padding: .3rem;
        border-radius: 2rem;
      }
    }
  }

  .slick-slide.is-active img {
    border-color: $color8;
  }
}

.fancybox-thumbs__list a::before {
  border: 4px solid $color8;
}

.tag-actu {
  display: inline-block;
  color: $color1;
  background-color: $color8;
  padding: .1rem .3rem;
  margin-bottom: .4rem;
}



/* Home Slider avec slide affichée 1 par 1 */

.single-slider.sliderhome,
.noslideshow.sliderhome {

  &.slick-slider {
    .slick-list,
    .slick-track {
      transform: none !important;

      .slick-slide {
        z-index: auto !important;
      }
    }
  }


  .slide {
    overflow: hidden;
    text-align: right;
    &::before {
      content:'';
      position: absolute;
      top: 0;
      bottom: 0.06rem; /* bug chrome */
      left: 0;
      right: 0;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2cb6d8+0,023a84+100&0.65+0,0.65+100 */
      background: -moz-linear-gradient(-45deg,  rgba(44,182,216,0.65) 0%, rgba(2,58,132,0.65) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  rgba(44,182,216,0.65) 0%,rgba(2,58,132,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  rgba(44,182,216,0.65) 0%,rgba(2,58,132,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a62cb6d8', endColorstr='#a6023a84',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    }
    a, .s-link {
      display: block;
      padding-left: 25%;
      @media (max-width: $breakpoint-sm) {
        padding-left: 30%;
      }
      @media (max-width: $breakpoint-xs) {
        padding-left: 40%;
      }
    }


    img {
      margin-left: auto;
      margin-right: 0;
      width: auto;
      object-fit: cover;
    }

    .islide-content {
      position: absolute;
      top: 0;
      bottom: 0;
      /*left: 50%;
      transform: translateX(-50%);*/

      @include flex-center;


      .islide {

        display: block;
        position: absolute;
        top: 0;
        bottom: -.1rem; /* Bug safari */
        left: 0;
        width: 24%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        text-align: left;
        padding: 0;

        @media (max-width: $breakpoint-sm) {
          width: 30%;
        }
        @media (max-width: $breakpoint-xs) {
          width: 40%;
        }

        .islide-content {
          position: relative;
          z-index: 2;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;

          text-align: left;
          padding: 0 0 10rem 11rem;

          @media (max-width: $breakpoint-xxl) {
            padding: 0 0 8rem 9rem;
          }
          @media (max-width: $breakpoint-xl) {
            padding: 0 0 5rem 6rem;
          }
          @media (max-width: $breakpoint-lg) {
            padding: 0 0 0 5rem;
          }
          @media (max-width: $breakpoint-sm) {
            padding: 0;
            margin-right: -5rem;
          }
          @media (max-width: $breakpoint-xs) {
            margin-left: 2rem;
          }

        }


        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: -.1rem; /* Bug safari */
          left: -34.9vw;
          width: 35vw;
        }
        &, &::before {
          background-color: #fff;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: -17.2rem;

          width: 0;
          height: 0;
          border-style: solid;
          border-width: 78.8rem 17.4rem 0 0;
          border-color: #fff transparent transparent transparent;

          @media (max-width: $breakpoint-sm) {
            right: -11.4rem;
          }
          @media (max-width: $breakpoint-xs) {
            right: -6.4rem;
          }

        }
        .h1, .h2, .h3 {
          color: $color8;
          text-align: left;
          text-transform: none;
          font-weight: normal;
        }
        .h1 {
          font-size: 4rem;
          line-height: 1.2;
          min-width: 28rem;
          @media (max-width: $breakpoint-lg) {
            font-size: 3.5rem;
            line-height: 1;
            min-width: 28rem;
          }
          @media (max-width: $breakpoint-md) {
            font-size: 3rem;
            min-width: 24rem;
          }
          @media (max-width: $breakpoint-sm) {
            font-size: 2rem;
            min-width: 21rem;
          }
          @media (max-width: $breakpoint-xs) {
            font-size: 2rem;
            min-width: 21rem;
          }
        }

        .h3 {
          font-size: 2rem;
          line-height: 1.6;
          @media (max-width: $breakpoint-lg) {
            font-size: 1.8rem;
            line-height: 1.4;
          }
          @media (max-width: $breakpoint-md) {
            font-size: 1.4rem;
            line-height: 1.3;
          }
          @media (max-width: $breakpoint-sm) {
            font-size: 1.2rem;
          }
          @media (max-width: $breakpoint-xs) {
            font-size: 1rem;
            display: none;
          }
        }

        .button {
          min-height: auto;
          @media (max-width: $breakpoint-md) {
            font-size: 1.2rem;
            padding: 1rem 1.5rem;
          }
          @media (max-width: $breakpoint-xs) {
            display: none;
          }

          &:hover {

          }
        }
      }
    }
  }

  .slick-prev {

  }

  .slick-next {

  }

  .slick-next::before,
  .slick-prev::before {

  }

  .slick-dots {


    li button::before {

    }
  }
}

.slider-faq {

  .slide {
    overflow: hidden;
    text-align: right;
    &::before {
      content:'';
      position: absolute;
      top: 0;
      bottom: 0.06rem; /* bug chrome */
      left: 0;
      right: 0;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003d80+0,023a84+100,003d80+100,20b0d5+100&0.8+0,0.8+100 */
      background: -moz-linear-gradient(-45deg,  rgba(0,61,128,0.8) 0%, rgba(32,176,213,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  rgba(0,61,128,0.8) 0%,rgba(32,176,213,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  rgba(0,61,128,0.8) 0%,rgba(32,176,213,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc003d80', endColorstr='#cc20b0d5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    }


    .islide-content {

      .islide {
        width: auto;
        padding: 0;
        background-color: transparent;
        .islide-content {

        }


        .h1, .h2, .h3 {
          color: $color8;
          text-align: left;
          text-transform: none;
          font-weight: normal;
        }
        .h1 {
          font-size: 4rem;
          line-height: 1.2;
          color: $color1;
          @media (max-width: $breakpoint-lg) {
            font-size: 3.5rem;
          }
          @media (max-width: $breakpoint-sm) {
            font-size: 3rem;
            line-height: 1.1;
          }
          @media (max-width: $breakpoint-xs) {
            font-size: 2rem;
          }
        }
      }
    }
  }


}


.single-slider-bandeau {
  padding-left: 0;
  margin-bottom: 0;

  .banner {
    min-height: 15rem;
  }


  .container-breadcrumb {
    z-index: 1;

    margin-top: 0;
    margin-bottom: 0;
  }

  .banner {

    .banner-product {
      display: flex;
      flex-direction: column;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003d80+0,20b0d5+100&0.8+0,0.8+100 */
        background: -moz-linear-gradient(left,  rgba(0,61,128,0.8) 0%, rgba(32,176,213,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,61,128,0.8) 0%,rgba(32,176,213,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,61,128,0.8) 0%,rgba(32,176,213,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc003d80', endColorstr='#cc20b0d5',GradientType=1 ); /* IE6-9 */

      }
      .no-link,
      .link {
        display: flex;
        flex-direction: column;
        height: 100%;

      }

      .title {
        position: relative;
        z-index: 1;
        text-align: center;

        margin: auto;
        color: $color1;
      }
      .mini-title {
        display: flex;
        color: #fff; /* #ffef00 */
        font-size: 1.3rem;
        margin-top: 0.5rem;
      }
      // var cercle activité
      $dim-cercle: 16rem;
      $dim-img: $dim-cercle - 6rem;

      .img-content {
        position: absolute;
        z-index: 1;
        right: 15rem;
        bottom: -7rem;

        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: $dim-cercle;
        height: $dim-cercle;
        border-radius: 50%;
        background-color: $color1;
        margin-top: 0;
        margin-bottom: 0;

        @media (max-width: $breakpoint-xl) {
          right: 9rem;
          bottom: -7.2rem;
          transform: scale(0.8);
        }
        @media (max-width: $breakpoint-lg) {
          right: 6rem;
          bottom: -7.5rem;
          transform: scale(0.6);
        }
        @media (max-width: $breakpoint-md) {
          right: 0rem;
        }

        .img-pattern {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

          left: -1rem;
          top: -1rem;

          overflow: hidden;
          width: calc(100% + 2rem);
          height: calc(50% + 2rem);
          /*background-color: rgba(100,0,0,0.3);*/

          &:before {
            content:'';
            position: absolute;
            left: 1rem;
            top: 1rem;

            width: $dim-cercle;
            height: $dim-cercle;

            border-radius: 50%;
            box-shadow: 0 0 .6rem .1rem rgba(0,0,0,0.2);
            /*background-color: rgba(0,100,0,0.3);*/
          }

        }
        img {
          position: relative;
          left: 0;
          top: 0;
          transition: all 0.3s ease-in-out;
          width: $dim-img;
          height: $dim-img;
          object-fit: contain;
        }

      }

      /*
      .banner-picto {
        position: absolute;
        z-index: 1;
        right: 15rem;
        bottom: -5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 15rem;
        height: 15rem;
        background-color: $color1;
        border-radius: 50%;
      }
      */
    }
  }



}


/* Slider avec des vignettes (page projet) */

.slider-single-projet {
  .slider-nav-container {
    position: relative;
    .slider-nav-content,
    figure {
      /*@include absolute-container;
      @include flex-center;*/
      cursor: pointer;
    }
  }
}

.slider-nav-projet {
  .slick-track {
    margin: 0 1rem 1rem;

    .slick-slide {
      /*margin-right: 1.5rem;*/

      img {
        /*object-fit: cover;*/
        /*@include box-shadow-light;*/
        cursor: pointer;
        /*height: 7.5rem;
        border: .2rem solid transparent;
        padding: .3rem;*/
      }
    }
  }

  .slick-slide.is-active img {
    border-color: $color8;
  }
  .slick-prev {
    left: -4rem;
  }

  .slick-next {
    right: -4rem;
  }

  .slick-next::before,
  .slick-prev::before {
    color: $color1;
  }

}

