.log-error {
  text-align: left;
  color: #d8000c;
  font-weight: 700;

  li {
    line-height: 2rem;
    margin: .5rem 0;
  }

  .picto {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin-right: .5rem;
    text-align: center;
    border: .1rem solid #d8000c;
    border-radius: 50%;
  }
}

.error-border {
  border: .2rem solid #d8000c !important;
}

.error {
  display: inline-block;
  color: $color1;
  background-color: #a72430;
  margin: 1rem 0;
  padding: .8rem;
}

.success {
  display: inline-block;
  color: $color2;
  background-color: #00b229;
  margin: 1rem 0;
  padding: .8rem;
}

input[type="submit"],
.button[type="submit"] {
  cursor: pointer;
}

/* Validation form */
.valid-form {
  input[type="email"],
  input[type="text"],
  input[type="password"],
  input[type="date"],
  textarea,
  select {
    display: block;
    border: .1rem solid #dee2e6;
    padding-left: 1.2rem;

    &:focus {
      border-color: #a4afba;
      outline: none;
      box-shadow: transparent;
    }

    &.valid {
      box-shadow: 0 0 .15rem .1rem green;
    }

    &.invalid {
      box-shadow: 0 0 .15rem .1rem #ffef00;
    }
  }

  input[type="checkbox"] ~ label {
    margin-left: .8rem;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 1.3;
  }

  input[type="radio"],
  input[type="radio"] + label {
    cursor: pointer;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 15rem;
    padding-top: .5rem;
  }

  em.valid,
  em.invalid,
  .error-form {
    display: block;
    margin-top: .2rem;
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-family: 'OpenSans', sans-serif;
    font-style: italic;
  }

  .error-form,
  em.invalid {
    color: #ffef00;
  }

  em.invalid + em.valid {
    display: none;
  }

  .list-checkbox .form-checkbox-container {
    display: inline-block;

    input[type="checkbox"] ~ label {
      margin-left: 0;
      margin-right: .5rem;
    }
  }

  .form-radio-container,
  .checkbox-container {
    position: relative;

    em {
      position: absolute;
      top: 2rem;
      left: 0;
    }
  }
}

/* recrutement / home */
.form_recrutement,
.bloc-rappel {

  form {

    /* Validation form */
    &.valid-form {
      input[type="email"],
      input[type="text"],
      input[type="password"],
      input[type="date"],
      textarea,
      select {
        &.invalid {
          box-shadow: 0 0 .15rem .1rem $color10;
        }
      }
      .error-form,
      em.invalid {
        color: $color10;
        width: 100%;
        text-align: right;
      }

      .input-effect {
        flex: 1 auto;
      }
    }

    .col-12 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top:0.5rem;
      margin-bottom:0.5rem;

      &.type-file {
        > label {
          color: $color9;
          font-weight: bold;
          margin-bottom: 1rem;
          width: 100%;
          display: flex;
        }
      }

      .label-title {
        font-size: 1.6rem;
        margin-right: 0.5rem;
      }
      .button {
        margin-right: auto;
        margin-left: auto;
        .icon {
          display: inline-flex;
          font-size: 1rem;
          margin-right: .5rem;
        }
      }

    }
  }
}


/* Focus effect sur les inputs */
.custom-form {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    display: block;
    height: 5.5rem !important;
    width: 100%;
    border: .1rem solid #bbb;
    border-radius: .5rem;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    z-index: 1;

    &:disabled {
      border-color: #eee;
    }
  }

  select {
    padding-top: .7rem;
  }

  textarea {
    display: block;
    width: 100%;
    border: .1rem solid #bbb;
    border-radius: .4rem;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    resize: none;
  }

  .input-effect {
    max-width: 39rem;
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-bottom: 2rem;
    margin-right: auto;

    .icon {
      margin-right: .6rem;
    }
  }

  .focus-effect {
    padding: .7rem 1.5rem;
    border: .1rem solid $color4;
    position: relative;
    background: transparent;
  }

  .focus-effect ~ label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 5.5rem;
    width: 100%;
    color: #888;
    border-radius: .4rem;
    background-color: $color1;
    margin-bottom: 0;
    padding-left: 1.2rem;
    padding-top: 1.6rem;
    transition: all .2s linear;
    z-index: 0;
    letter-spacing: .5px;
  }

  .focus-effect:focus ~ label,
  .has-content.focus-effect ~ label {
    top: 0;
    font-size: 11px;
    color: #888;
    padding-top: .2rem;
    transition: all .2s linear;
  }

  .form-radio-container {
    margin-bottom: 2rem;
    max-width: 39rem;
    margin-right: auto;
    margin-left: auto;

    .libelle {
      margin-right: 2rem;
    }

    input ~ input {
      margin-left: 2rem;
    }
  }

  .form-checkbox-container {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;

    label {
      display: inline;
    }
  }
}




/* checkobox perso */
input[type=checkbox],
input[type=radio]{
  opacity : 0;
  position:absolute;
  top : 50%;
  transform: translateY(-50%);
}
/* Taille checkbox */
input[type=checkbox],
input[type=radio],
input[type=checkbox] + label > span.checkbox,
input[type=radio] + label > span.radio {
  width: 2.1rem;
  height: 2.1rem;
}
input[type=checkbox] + label,
input[type=radio] + label {
  line-height : 18px;
  padding-left : calc(2.1rem + 1.2rem);
  display:block;
  cursor:pointer;

  position:relative;
  display: flex;
  align-items: center;
}


input[type=checkbox] + label > span.checkbox,
input[type=radio] + label > span.radio {
  display: flex;
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  justify-content: center;
  align-items: center;
}
.input-top input[type=checkbox] + label > span.checkbox,
.input-top input[type=radio] + label > span.radio {
  top : 4px;
  margin-top : auto;
}

input[type=checkbox] + label > span.checkbox {
  background-color:#fff;
  border:2px solid rgba(0,0,0,0.12);
  border-radius: 0.3rem;
}

input[type=radio] + label > span.radio {
  background-color:#fff;
  border:2px solid rgba(0,0,0,0.12);
  border-radius:50%;
}

input[type=checkbox]:checked + label > span.checkbox:before {
  color: $color11;
  font-size:12px;
  content: "\EA1E";
  font-family: 'kfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
  height: auto;
  display:block;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

input[type=radio]:checked + label > span.radio:before {
  content:'';
  background-color:#4c4c4c;
  border-radius:9px;
  width : 12px;
  height : 12px;
  display:block;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

}





/* SELECT 2 */
.select2-container{
  display: inline-block;
  vertical-align: middle;
}
.search-content {
  .select2-container{
    width: 100%!important;
    max-width: 35rem!important;
  }
}

.select2-container--default .select2-search--inline .select2-search__field{
  height: 2rem;
  line-height: 1;
  margin-bottom: .4rem;
  cursor: pointer;
  pointer-events: none;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple{
  border-color: transparent;
  height: 4rem;
  cursor: pointer;
  line-height: 2;
  font-size: 1.6rem;
}

.select2-container--default .select2-selection--multiple{
  margin-top: .3rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice{
  display: none;
}

.select2-results__option--selectable{
  position: relative;
  margin-left: .6rem;
  font-size: 1.4rem;
  margin-right: .6rem;
  padding-top: .7rem;
  padding-bottom: .7rem;
  padding-right: 0;
  line-height: 1;
  margin-bottom: .3rem;
}

.select2-dropdown {
  border-radius: 0 0 2rem 2rem!important;
  margin-top: 0.6rem;
  border: none;
  box-shadow: 0 .2rem .6rem 0rem rgba(23,142,193,.3);
  z-index: 4;
}

.select2-container--default .select2-results > .select2-results__options {
  margin-top: .5rem;
  margin-bottom: .6rem;
  padding: 1rem;

  max-height: 24rem;
}

.select2-container--default .select2-results__option--selected,
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
  color: #5a5a5a;
  background-color: transparent;
}
.select2-container--default .select2-results__option--selected {
  /*font-weight: bold;*/
}
.select2-container .select2-dropdown {
  /*width: 25rem !important;*/
}




.filtre-form {
  position: relative;
  background-color: $color1;
  @media (max-width: $breakpoint-xl) {
    z-index: 1;
    margin-top: 3rem;
  }
  @media (max-width: $breakpoint-lg) {
    margin-top: 2rem;
  }


  .search-content {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 4rem;

    .input-c{
      display: flex;
      width: calc(100%);
      justify-content: start;

      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        align-items: center;
      }

      .select2-c {
        width: 100%;
        max-width: 35rem;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 1rem;
        }
      }


      img{
        width: 2.5rem;
        object-fit: contain;
        margin-right: .8rem;
      }
      .input-item{
        display: flex;
        align-items: center;
        height: 4.5rem;
        background-color: #fff;
        border-radius: 4rem;
        padding-left: 1.5rem;
        padding-right: 2rem;
        margin-left: 2rem;


        padding: 0 2rem;
        height: 5.5rem;
        border: .2rem solid $color14;

        @media (max-width: $breakpoint-md) {
          margin-left: 0;
        }

        input{
          border: none;
          background-color: transparent;
          &::placeholder{
            font-size: 1.4rem;
            font-style: italic;
          }
          &.code_post{
            flex-grow: 2;
          }
        }
        &.input_cp_ville{
          width: calc(100% - 70rem);
          margin-left: 0;
        }
        &.select2-open .select2-selection--multiple{
          color: #002e48;
        }
      }
      select{
        position: absolute;
        border-radius: 4rem;
        border: none;
        background-color: white;
        padding: 0 1rem;
        flex-grow: 1;
        margin-left: 3rem;
        margin-right: 3rem;
        &:first-of-type{
          border-right: 1px solid rgba(0, 0, 0, .5);
          border-left: 1px solid rgba(0, 0, 0, .5);
        }
      }
      button{
        flex-grow: 1;
        margin-left: 10px;
        //margin-left: 30px;
        //margin-right: 170px;
      }
    }
    .select2-c .input-item{
      position: relative;
      &::after{
        content: "\EA10";
        font-family: kfont;
        position: absolute;
        right: 2rem;
        top: 50%;
        transition: transform .3s;
        transform: rotateX(0deg) translateY(-50%);
        font-size: 0.8rem;
        color: $color9;
        pointer-events: none;
      }
      &.select2-open::after{
        transform: rotateX(180deg) translateY(50%);
      }
      .counter,
      .select2-search textarea::placeholder{
      }
    }
  }
}


.search-content {
  .zone-select {

    .item-zone {
      &,
      & input {
        height: 5.5rem;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      margin-bottom: 2rem;
      background-color: $color1;
      border-radius: 4rem;
      padding: 0 2rem;

      > .icon {
        display: flex;
        color: $color15;
        font-size: 3rem;
        order: 1;
        transition: all 0.3s ease-in-out;
      }
      > .bt-search {
        order: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        /*position: absolute;
        right: 1.4rem;
        top: 50%;
        transform: translateY(-50%);*/
        width: 4.3rem;
        height: 4.3rem;
        border-radius: 50%;
        text-align: center;
        padding: 0;
        margin-right: -1rem;
        > .icon {
          display: flex;
          font-size: 2rem;
        }

        &:hover,
        &:focus {
          color: #fff;
          background-color: $color10;
          border-color: $color10;
        }
      }

      .input-effect,
      .checkbox-container {
        max-width: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: auto;
        margin-top: auto;

        input {
          border: none;
          & ~ label {
            background-color: transparent;
            color: $color15;
          }
        }


      }
      .input-container {
        order: 2;
        flex: 1;
        &:hover {
          & + .icon {
            color: $color11;
          }
        }
        input {

          &.valid {
            box-shadow: none;
          }
        }
      }

      .checkbox-container {
        position: relative;
        input {
          & ~ label {
            color: $color18;
            font-weight: normal;
            margin-left: 0;
            .picto {
              margin-right: 1rem;
            }

          }
          &:checked {
            + label {
              font-weight: bold;
            }
          }
        }

      }

    }
  }
}

.select2-results__option--selectable {
  margin-left: 3.5rem;
  /*white-space: nowrap;*/

  &::before,
  &::after{
    position: absolute;
    top: 50%;
    left: -2.5rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateY(-50%);
  }
  &::before{
    content: '';
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2.1rem;
    width: 2.1rem;
    height: 2.1rem;
    margin: 0;
    text-align: center;
    color: #5a5a5a;
    background-color: #fff;
    border: 2px solid rgba(0,0,0,.12);
    border-radius: .3rem;
  }
  &.select2-results__option--selected::after{
    content: "\EA1E" !important;
    font-family: kfont;
    font-size: inherit;
    color: #a9c741;
    font-size: 12px;
    text-rendering: auto;

    top: calc(50% + .1rem);
    margin-left: .3rem;
    margin-top: .1rem;

  }
  @media (max-width: $breakpoint-lg) {
    .picto {
      display: none;
    }
  }
}


/* JQUERY-UI .ui-widget.ui-widget-content */
.ui-menu.ui-widget.ui-widget-content {
  border-radius: 0!important;
  border: none;
  box-shadow: 0 .4rem .6rem 0rem rgba(23,142,193,.3);
  margin-top: -.1rem;
  padding: 1rem 0 2rem 0;

  max-height: 40rem;
  overflow-x: hidden;
  overflow-y: auto;

  .ui-menu-item-wrapper {
    padding: .4rem 1.3em .4rem 1.3rem;
  }
  .ui-menu-item {
    list-style-image: none;
  }

  .ui-button.ui-state-active:hover,
  .ui-button:active,
  .ui-state-active {
    border: none;
    background: $color16;
    font-weight: 400;
    color: #fff;
  }
}


