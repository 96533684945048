/*** Style generique ***/
body,
button,
fieldset,
form,
html,
textarea {
  font-family: 'Open Sans', sans-serif;
}

body,
html {
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-size: 1.4rem;
}

body,
body.mce-content-body {
  color: $color8;
}

/* Saut à la ligne CMS et BUILDER EditeurTexte */
.clear {
  clear: both;
}

.scroller {
  overflow-x: hidden;
}

.aheight {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle
}

.mini,
.fontsize80 {
  font-size: 80%
}

.fontsize85 {
  font-size: 85%
}

.fontsize90 {
  font-size: 90%
}

.fontsize110 {
  font-size: 110%
}

.big,
.fontsize120 {
  font-size: 120%
}


.lowercase {
  text-transform: lowercase
}

.uppercase {
  text-transform: uppercase
}

.nocase {
  text-transform: none;
}

figure {
  margin: auto;
}

.hidden,
.mobile,
figcaption {
  display: none;
}

.desktop {
  display: block;
}

:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #91a1a1;
  font-style: italic;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3rem;
}

img {
  max-width: 100%;
  height: auto;
}

.cms img {
  height: auto;
}

.icon,
.text {
  display: inline-block;
  vertical-align: middle;
}

// Liens
a, .lien-off {
  transition: all .3s ease-in-out;
  text-decoration: none;
}
.lien-off {
 cursor: pointer;
}
.cms a:not(.button),
.mce-content-body a:not(.button),
a,
.cms .lien-off:not(.button),
.mce-content-body .lien-off:not(.button),
.lien-off {
  color: $color9;
  cursor: pointer;
  &:active,
  &:hover{
    color: $color8;
  }
}

.cms a:not(.button):focus,
.cms a:not(.button):hover,
.mce-content-body a:not(.button):focus,
.mce-content-body a:not(.button):hover,
a:focus,
a:hover,
.cms .lien-off:not(.button):focus,
.cms .lien-off:not(.button):hover,
.mce-content-body .lien-off:not(.button):focus,
.mce-content-body .lien-off:not(.button):hover,
.lien-off:focus,
.lien-off:hover {
  text-decoration: none;
}

// Listes
.cms ol,
.cms ul,
.faq-answer ol,
.faq-answer ul,
.mce-content-body ol,
.mce-content-body ul {
  line-height: 1.5;
  list-style-type: inherit;
}

.cms ol li,
.cms ul li,
.faq-answer ol li,
.faq-answer ul li,
.mce-content-body ol li,
.mce-content-body ul li {
  list-style-position: inside;
  margin: .4rem auto .4rem 0;
  @media (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

.cms ol,
.faq-answer ol,
.mce-content-body ol {
  list-style-type: decimal;
}

.liste li,
.body_frise_historique .grid-builder-content ul li {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  list-style-type: none;
  list-style-position: inside;
  padding: .2rem 0;
  &::before {
    font-family: 'kfont';
    content: "\EA16";
    color: $color9;
    font-size: 1rem;
    margin-right: 1rem;

  }
}
.liste2 li {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1rem;
  padding: .2rem 0;
}

// Tables
.table {
  display: table;
  border: .1rem solid #e3e3e3;
  border-collapse: collapse;
  font-size: 1.1rem;
  background-color: $color1;
  color: #3d3d3d;
}

.table-container {
  width: 87vw;
  overflow-y: auto;
  overflow-x: auto;
}

.table td,
.table th {
  border: .1rem solid #e3e3e3;
  padding: .5rem 1rem;
}

.table th {
  padding: 1rem;
}

.td {
  display: table-cell;
}

// Classes génériques + classe zoom pour fancybox
.cms {
  .box-shadow .content {
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .5);
  }

  .vertical-center .box {
    margin-top: auto;
    margin-bottom: auto;
  }
}

// Accordeon
.entete-accordeon,
.ui-accordion .entete-accordeon {
  background: #209cbf none repeat scroll 0 0;
  border: .1rem solid #209cbf;
  color: $color1;
  font-size: 1.9rem;
  padding: .3rem 1rem;
  border-radius: 0;
}

.mce-content-body .stitre.entete-accordeon:after,
.mce-content-body h2.entete-accordeon:after,
.mce-content-body h2:after {
  border-bottom: none;
  margin: auto;
  width: auto;
  display: inline-block;
}

/*** /Style generique ***/
.grid-builder {
  flex: 1 auto;
}
.grid-builder-content {
  position: relative;
  .row {
    /*margin: 0 auto 0 auto;*/
    padding: 2rem;
    /*overflow: hidden;*/
    color: $color18;
  }
}

#cms .scroller > .content {
  margin-top: 5rem;
}

#cms #bloc {
  padding: 2rem 2rem 0;
}

#cms .content .container {
  margin: 0 auto;
  width: 114rem;
  overflow: hidden;
  background-color: $color1;
  min-height: 35rem;
}

#cms a.bx-next,
#cms a.bx-prev {
  color: $color1;
}

.page-cms .vertical-center {
  display: flex;
  align-items: center;
}

/* Spé SEMA */
.cms {
  .chapo {
    font-size: 1.7rem;
    @media (max-width: $breakpoint-md) {
      font-size: 1.4rem;
    }
  }
}

.grid-builder-content {
  .container-full {
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
  }
}
.grid-builder-content,
.grid-builder {
  &.item-max-width {
    .container {
      max-width: $breakpoint-lg;
    }
  }
}
.content {
  position: relative;
  z-index: 2; // par dessus pattern
}
/* Equipe */
.item-ligne {

  .item-bloc {
    .content {
      margin-left: 4rem;
      margin-right: 4rem;
      @media (max-width: $breakpoint-lg) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
      @media (max-width: $breakpoint-md) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      @media (max-width: $breakpoint-sm) {
        margin-left: 0;
        margin-right: 0;
      }

        img {
          max-width: 25rem;
        }
    }
  }
  .item-bloc-radius {
    .content {
      img {
        border-radius: 50%;
      }
    }
  }

}

.pattern-picto {
  /*position: relative;*/

  &::before {
    position: absolute;
    z-index: 1;
    top: -1vw;
    right: 0;
    display: flex;

    font-family: kfont;
    content: "\EA56";
    font-size: 18vw;
    color: $color1;
  }

    .pattern-picto-right {

  }
}

.builder-slider {
  .equipe-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          margin-left: 3rem;
          margin-right: 3rem;
          .slide {
            .bloc-img {
              img {
                margin: auto;
                border-radius: 50%;
              }
            }
            .bloc-txt {
              .title,
              .type {
                text-align: center;
              }
              .title {
                margin-top: 1.5rem;
              }
              .type {
                color: $color16;
              }
            }
          }

        }
      }
    }

    .slick-next, .slick-prev {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background-color: $color14;
      box-shadow: 0 .2rem .6rem 0 rgba(0,0,0,0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slick-prev {
      left: 3rem;
    }
    .slick-next {
      right: 3rem;
    }
  }

}