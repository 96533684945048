@mixin title-global {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

@mixin h1 {
  position: relative;
  color: $color8;
  font-size: 3.2rem;
  font-weight: normal;
  margin: 1rem 0 2rem;
  line-height: 1;
  /*text-align: center;*/
  @media (max-width: $breakpoint-md) {
    font-size: 2.5rem
  }
  @media (max-width: $breakpoint-xs) {
    font-size: 2rem
  }
  a {
    color: $color8;
  }
}

@mixin h2 {
  font-size: 3.2rem;
  color: $color8;
  margin: 1rem 0 2rem;
  font-weight: normal;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2.2rem
  }
}

@mixin h3 {
  font-size: 2.5rem;
  color: $color8;
  margin: 1rem 0;
  font-weight: normal;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
  a {
    color: $color8;
  }

}

@mixin h4 {
  font-size: 2rem;
  color: $color8;
  margin: .6rem 0;
  font-weight: normal;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 1.8rem
  }
  a {
    color: $color8;
  }

}

@mixin h5 {
  font-size: 1.8rem;
  color: $color8;
  margin: .6rem 0;
  font-weight: normal;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 1.6rem
  }
  a {
    color: $color8;
  }

}

@mixin h6 {
  font-size: 1.6rem;
  color: $color8;
  margin: .6rem 0;
  font-weight: normal;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 1.4rem
  }
  a {
    color: $color8;
  }

}


@mixin sous-titre {
  font-size: 2rem;
  color: $color16;
  margin: 1rem 0 2rem 0;
  font-weight: normal;
  line-height: 1;
  @media (max-width: $breakpoint-md) {
    font-size: 1.6rem
  }
  a {
    color: $color8;
  }

}