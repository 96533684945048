.container-breadcrumb {
  position: relative;
  z-index: 55;
  top: 2rem;
  height: 0;
}
.breadcrumb {
  /*overflow: hidden;*/
  min-height: auto;
  margin-top: 0;
  margin-bottom: 0;
  color: $color1;
  font-size: 1.3rem;

  a, .lien-off {
    color: $color1;
    margin-right: 0.5rem;

    &:hover {
      color: $color17;
    }
  }

  .breadcrumb-sep {
    margin-right: 0.5rem;
  }
  @media (max-width: $breakpoint-sm) {
    font-size: 1.1rem;
  }
}


.container-breadcrumb {
  &.breadcrumb-color-2 {
    .item {
      color: $color18;
    }
    .lien-off, a{
      color: $color18;
      &:hover {
        color: $color9;
      }
    }
    .breadcrumb-sep {
      color: $color15;
    }
  }
}
