.mp-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 30rem;
  height: 100vh;
  background: $color1;
  transform: translateX(-100%);
  transition: transform .5s ease-in-out;

  > .mp-level {
    top: 10.5rem;
  }
}

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 5rem);
  overflow: hidden;
  background: #F4F4F4;
  transform: translateX(-100%);
}

/* Partie Logo */
.logo-mh {
  @include flex-center;
  text-align: center;
  height: 10.5rem;

  img {
    width: 15rem;
  }
}

.mp-level.mp-level-open,
.mp-menu > .mp-level.mp-level-open {
  height: calc(100% - 5rem);
}

.mp-level.mp-level-open {
  overflow: auto;

  .mp-level-overlay {
    overflow: hidden;
    height: 200vh;

    > ul > li:not(.m-current) {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.mp-level.mp-level-overlay {
  cursor: pointer;

  &.mp-level:before {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
  }
}

.mp-pusher,
.mp-level {
  transition: transform .5s ease-in-out;
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  box-shadow: none;
  transform: translateX(0);
  transition: transform .5s ease-in-out;
}

/* cover */
.mp-cover .mp-level.mp-level-open {
  transform: translateX(0);

  > ul > li > .mp-level:not(.mp-level-open) {
    transform: translateX(-100%);
  }
}

/* titles */

.mp-menu .title {
  margin: 0 2rem;
  color: rgba(0, 0, 0,.7);
  text-shadow: 0 0 .1rem rgba(0, 0, 0, .1);
  font-size: 1.6rem;
  min-height: 4rem;
  line-height: 4rem;
  padding: .7rem 0;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  text-align: center;
}

#mp-menu .mp-level-2 .menu-container span.text {
  vertical-align: middle;
  font-size: 2rem;
}

.mp-menu .title .picto + .text {
  margin-left: 0;
}

.mp-menu .mp-level .mp-level .mp-level .title {
  color: $color1;

  a,
  .lien-off {
    vertical-align: middle;
    line-height: 1.8rem;
    display: inline-block;
    padding-bottom: .3rem;
  }
}

.mp-menu .title a:hover,
.mp-menu ul li .title a:hover,
.mp-menu .title .lien-off:hover,
.mp-menu ul li .title .lien-off:hover,
.mp-menu .title a:focus,
.mp-menu ul li .title a:focus,
.mp-menu .title .lien-off:focus,
.mp-menu ul li .title .lien-off:focus{
  background: transparent;
  color: #56c1ff;
}

.mp-menu .title .picto,
.mp-menu .title a .picto,
.mp-menu .title .lien-off .picto {
  font-size: 4rem;
  line-height: 4rem;
  overflow: visible;
  width: auto;
  margin: 0 .5rem;
}

.mp-menu .title .picto + .centered-height {
  width: calc(100% - 6rem);
}

.mp-menu.mp-overlap .title:before {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: .8rem;
  font-size: 75%;
  line-height: 1.8;
  opacity: 0;
  transition: opacity .3s, transform .1s .3s;
  transform: translateX(-100%);
}

.mp-overlap .mp-level.mp-level-overlay > .title:before {
  opacity: 1;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  transform: translateX(0);
}

/* lists */

.mp-menu ul {
  list-style: none;
  text-align: left;
  overflow: hidden;

  li:before {
    position: absolute;
    left: 1rem;
    z-index: -1;
    color: rgba(0, 0, 0, .8);
    line-height: 3rem;
  }
}

.mp-menu > .mp-level > ul {
  margin: 0;
  padding: 0;
  overflow: auto;
  width: 30rem;
  height: 100%;
  position: relative;
}

/* lists links */

.mp-menu ul li > a,
.mp-menu ul li > .lien-off {
  display: block;
  padding: .7em 1em .7em 1em;
  outline: none;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  transition: background .3s ease-in-out, box-shadow .3s ease-in-out;

  .icon {
    font-size: 1.2rem;
  }
}

.mp-level a,
.mp-level .lien-off {
  color: rgba(0, 0, 0, .7);
}

#mp-menu .mp-level ul {
  list-style: none;
}

#mp-menu .mp-level-2 {
  .menu-container a,
  .menu-container .lien-off,
  .menu-container span {
    color: $color2;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
  }

  .menu-container span {
    color: rgba(0, 0, 0, .7);
    transition: all .3s ease-in-out;

    &:hover,
    &:focus {
      color: #bcc6cf;
    }
  }

  a,
  .lien-off {
    text-transform: none;
    line-height: 2rem;
    vertical-align: middle;

    &.mp-back {
      text-transform: uppercase;
      padding-left: 1rem;

      &:hover,
      &:focus {
        color: $color9;
        text-decoration: none;
        padding-left: 2rem;
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0 .5rem;
    }
  }
}

.mp-level a,
.mp-level .lien-off {
  padding-left: 0;
  vertical-align: middle;
  transition: border-color .2s ease-in, padding .2s ease-in;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.mp-level > ul > li > a,
.mp-level > ul > li > .lien-off {
  transition: all .3s ease-in-out;
  box-shadow: inset 0 -.1rem rgba(0, 0, 0, .2);

  &:hover,
  &:focus {
    color: $color9;
    text-decoration: none;
    padding-left: 2em;
  }
}

.mp-level .espace-mobile {
  background-color: transparent;
  border: none;
  line-height: 2.2rem;
  padding: .7em 1em .7em 1em;
  outline: none;
  cursor: pointer;
  color: $color1;

  .picto {
    margin: 0 1rem 0 0;
    font-size: 2.2rem;
    width: 2.4rem;
    overflow: visible;
  }

  &:hover,
  &:focus {
    .text,
    .picto {
      color: $color2;
    }
  }
}

.mp-menu.out ul li a:hover,
.mp-menu.out ul li a:focus {
  background: rgba(0, 0, 0, .2);
  box-shadow: inset 0 -.1rem rgba(0, 0, 0, 0);
}

#mp-menu .mp-level {
  .picto-menu {
    position: static;
    margin: 0 0 0 .6rem;
    vertical-align: middle;
    border-color: transparent transparent transparent rgba(0, 0, 0, .7);
    transition: border-color .2s ease-in, margin .3s ease-in-out;
  }

  a:hover .picto-menu,
  a:focus .picto-menu {
    border-color: transparent transparent transparent $color1;
  }
}

/* back buttons */

.mp-back {
  background: rgba(0, 0, 0, .1);
  outline: none;
  color: $color1;
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-weight: 700;
  display: block;
  font-size: 1.2em;
  padding: 1em 1em 1em 2.4em;
  position: relative;
  box-shadow: inset 0 .1rem rgba(0, 0, 0, .1);
  transition: color .2s ease-in-out, background .3s ease-in-out, padding .2s ease-in-out;

  &:hover,
  &:focus {
    padding: 1em 1em 1em 2.7em;

    .picto-menu {
      border-color: transparent $color1 transparent transparent;
    }
  }

  .picto-menu {
    border-color: transparent rgba(0, 0, 0, .7) transparent transparent;
  }
}

.mp-menu.out .mp-level.mp-level-overlay > .mp-back {
  background: transparent;
  box-shadow: none;
  color: transparent;
}

#mp-menu .mp-back::before,
#mp-menu .mp-level-2 .mp-back::before {
  content: "\EA13";
  font-family: 'kfont' !important;
  vertical-align: middle;
  margin-right: 1rem;
}

.mp-level li {
  margin-left: 0;
}

.m-event:after {
  content: "\EA16";
  font-family: 'kfont' !important;
  margin-right: 4rem;
  vertical-align: middle;
  float: right;
}

/* fancy mobile rules */
.fancybox-inner,
#fancybox-content > div {
  overflow: hidden !important;
}

#fancybox-content > div img {
  width: 100%;
}

/* ***************** */

.menu-visible html {
  overflow: hidden;
}

.mp-pusher {
  transition: transform .5s ease-in-out;

  &.fancy-visible {
    transform: translateX(34rem);
  }

  &.menu-visible {
    transform: translateX(30rem);
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
  }
}

.fancy-visible .scroller,
.menu-visible .scroller {
  overflow: hidden;
  height: 100%;
}

.mp-fancy {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-34rem);
  width: 34rem;
  z-index: 1;
}

#mp-fancy > .header {
  border-bottom: .5rem solid #5cb39b;
  position: relative;
}

.scroller {
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    display: block;
    z-index: 9999;
  }

  &.mp-pushed {
    cursor: pointer;

    &::before {
      left: 0;
      bottom: 0;
      width: auto;
      height: auto;
      background: rgba(0, 0, 0, .5);
      transition: background .5s ease-in-out;
    }
  }
}

/* Launcher du menu */
.menu-launcher {
  position: relative;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
}

#header.sticky .menu-launcher {
  top: .4rem;
}

/* hamburger */

.picto-menu {
  position: absolute;
  top: 1.6rem;
  left: 0;
  width: 2.8rem;
  height: .3rem;
  background: $color8;
  display: inline-block;
  transition: all .3s ease-in-out 0s;

  &::before,
  &::after {
    background: $color8;
    content: "";
    display: inline-block;
    height: .3rem;
    left: 0;
    position: absolute;
    transform-origin: center center 0;
    transition: all .3s ease-in-out 0s;
    width: 2.8rem;
  }

  &::before {
    top: .8rem;
  }

  &::after {
    top: -.8rem;
  }
}

body.menuhidden {
  overflow: hidden;
}

.menuhidden .picto-menu {
  background: transparent none repeat scroll 0 0;

  &::before,
  &::after {
    top: 0;
    transform-origin: 50% 50% 0;
  }

  &::before {
    height: .2rem;
    transform: rotate(45deg);
  }

  &::after {
    height: .2rem;
    transform: rotate(-45deg);
  }
}

@media (min-width: 991px) {
  .menu-launcher {
    display: none;
  }
}

@media (max-width: 767px) {
  .mp-level.mp-level-open.mp-level-overlay {
    overflow: hidden;
    height: 200vh;
  }
}