
/* HEADER */
#header {
  position: relative;
  z-index: 5;
  height: 13rem;
  background-color: $color1;
  border-bottom: 2px solid $color13;

  @media (max-width: $breakpoint-sm) {
    height: 5rem;
  }

  .access {
    /*height: 4.8rem;*/
    @media (max-width: $breakpoint-sm) {
      /*height: 3.2rem;*/
    }
  }

  .header-content {
    height: calc(100%);
  }
}

.header-content {

  .container-bloc {
    @include flex-between;
    height: 100%;
  }

  .left-col {
    @media (max-width: $breakpoint-xl) {
      margin-top: -5rem;
    }
    @media (max-width: $breakpoint-md) {
      margin-top: 0;
    }
    .header-logo img {
      width: 24.6rem;
      @media (max-width: $breakpoint-xl) {
        width: 20rem;
      }
      @media (max-width: $breakpoint-lg) {
        width: 20rem;
      }
      @media (max-width: $breakpoint-sm) {
        width: 13rem;
      }
    }
  }

  .right-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    flex: 1;
    height: 100%;

    /* Access topbar */
    .access {
      background-color: $color1;
      font-size: 1.6rem;
      margin-right: 0;
      margin-left: auto;
      width: 100%;
      border-bottom: 2px solid $color13;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      height: 100%;
      @media (max-width: $breakpoint-md) {
        border-bottom: none;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 1.2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

      }

      .container-bloc {
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        color: $color8;

        .menu-access-header {
          margin-right: 1rem;
          @media (max-width: $breakpoint-md) {
            margin-right: 0;
          }

          .menu-acces {
            .level1 {
              display: flex;

              position: relative;
              margin: 0;
              padding: 0;
              list-style: none;
              font-size: 1.3rem;

              .acces-menu-item {
                position: relative;
                margin: 0 1rem;
                &:last-child {
                  margin-right: 0;
                }
                a,
                .lien-off {
                  color: $color8;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                  height: 100%;

                  [class^="icon-"],
                  [class*=" icon-"] {
                    display: flex;
                    margin-right: 0.8rem;
                    font-size: 2rem;

                    position: relative;
                    left: 0;
                    transition: all 0.3s ease-in-out;
                    @media (max-width: $breakpoint-md) {
                      margin-right: 0;
                    }
                  }

                  &:hover,
                  &:focus {
                    color: $color9;
                    [class^="icon-"],
                    [class*=" icon-"] {
                      left: -0.1rem;
                      transform: scale(1.2);
                      @media (max-width: $breakpoint-md) {
                        left: 0;
                      }
                    }
                  }
                  &.item-devis,
                  &.item-mail {
                    border-radius: 3rem;
                    padding: 1.5rem 3rem;
                    @media (max-width: $breakpoint-md) {
                      padding: 1rem 1rem;
                    }

                  }
                  &.item-devis {
                    background-color: $color10;
                    color: $color1;
                    &:hover,
                    &:focus {
                      background-color: $color1;
                      color: $color10;
                      box-shadow: inset 0 0 0 1px $color10;
                    }
                  }
                  &.item-mail {
                    background-color: $color9;
                    color: $color1;
                    &:hover,
                    &:focus {
                      background-color: $color1;
                      color: $color9;
                      box-shadow: inset 0 0 0 1px $color9;
                    }
                    [class^="icon-"],
                    [class*=" icon-"] {
                      font-size: 1.4rem;
                    }
                  }

                  @media (max-width: $breakpoint-lg) {
                    .text {
                      display: none;
                    }
                  }
                }

                .level2 {
                  max-height: 0;
                  transition: all .2s ease-in-out;
                  overflow: hidden;
                  position: absolute;
                  z-index: 1;
                  top: 100%;
                  right: 0;
                  background-color: $color14;
                  min-width: 18rem;
                  border-radius: 2rem 2rem 1rem 1rem;


                  margin: 0;
                  padding: 0;
                  list-style: none;
                  li {
                    padding: 0.5rem 1rem;
                    font-size: 80%;
                  }

                }

                &:hover {
                  .level2 {
                    max-height: 20rem;
                    padding: 1rem 0;
                  }
                }

              }


            }
          }
        }

      }
    }


  }
}

body:not(#home) {
  #contenu {
    position: relative;
    z-index: 2;
  }
}


/* Colone droite : Menu principal + réseaux sociaux */
.right-col {
  width: calc(100%);
  margin-left: 3vw;
  height: 100%;
  @media (max-width: $breakpoint-xxl) {
    margin-left: 3vw;
  }
  @media (max-width: $breakpoint-xl) {
    margin-left: 3vw;
  }
  @media (max-width: $breakpoint-lg) {
    margin-left: 3rem;
  }
  @media (max-width: $breakpoint-sm) {
    margin-left: 2rem;
  }
}


.menu {
  width: calc(100%);
  padding-right: 1rem;
  height: 100%;
  font-size: 1.4rem;
  font-weight: 600;

  @media (max-width: $breakpoint-xl) {
    width: calc(100% + 10vw);
    margin-left: -10vw;
    font-size: 1.3rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .level1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    > li {
      display: flex;
      align-items: center;
      height: 100%;
      &.menu-item {
        .menu-item-label {

          &::after{
            content: "";
            position: absolute;
            bottom: .2rem;
            left: 0;
            display: block;
            height: .3rem;
            width: 0;
            background-color: $color9;
            margin: .3rem auto -.3rem;
            transition: width .3s;
          }
        }
        &.col_right .menu-item-label::after{
          background-color: $color4;
        }
        .icon {
          display: flex;
          font-size: 0.7rem;
          color: $color9;
          margin-left: 1rem;
          transition: all .3s ease-in-out;
        }
        &:hover {
          .menu-item-label::after {
            width: 100%;
          }
          .icon {
            transform: rotateX(180deg);
          }
        }

      }
      &.sep{
        display: block;
        height: 2.2rem;
        width: .2rem;
        margin-top: 3.4rem;
        background-color: $color4;
        &:last-child{
          display: none;
        }
      }
      &.current {
        > a,
        > a:hover,
        > .lien-off,
        > .lien-off:hover{
          color: $color9;
        }
      }

    }
  }

  .level2.menu-type-1 {
    display: flex;
    flex-direction: row;
      .container-menu {
        display: flex;
        flex-direction: row;

        .menu-level1 {
          display: flex;
          flex-direction: column;
          flex: 1 auto;
          margin-bottom: 4rem;

          .title {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 2rem;
          }

          .nav {
            display: flex;
            flex-direction: column;
            line-height: 1.3;
            font-size: 1.4rem;
            font-weight: normal;
            /*height: 100%;*/
            > li {
              > a,
              > .lien-off {
                justify-content: start;
                padding: 0.8rem 0;
              }

              &.current {
                > a,
                > a:hover,
                > .lien-off,
                > .lien-off:hover{
                  color: $color9;
                }
              }
            }
          }
        }

        .menu-level2 {
          display: flex;
          flex-direction: column;
          flex: 4 auto;

          .nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            line-height: 1.3;
            font-size: 1.4rem;
            font-weight: normal;
            height: 100%;
            > li {
              flex: 1 0;
              min-height: 30rem;

              > a,
              > .lien-off {
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: start;

                background-position: center center;
                background-repeat: no-repeat;
                background-size: 130%;
                padding: 4rem;

                color: $color1;
                transition: all .2s ease-in-out;

                @media (max-width: $breakpoint-lg) {
                  padding: 2rem;
                  background-size: 145%;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003379+0,25add3+100&0.74+0,0.74+100 */
                    background: -moz-linear-gradient(-45deg, rgba(0, 51, 121, 0.74) 0%, rgba(37, 173, 211, 0.74) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(-45deg, rgba(0, 51, 121, 0.74) 0%, rgba(37, 173, 211, 0.74) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(135deg, rgba(0, 51, 121, 0.74) 0%, rgba(37, 173, 211, 0.74) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bd003379', endColorstr='#bd25add3', GradientType=1); /* IE6-9 fallback on horizontal gradient */
                }

                .title {
                  position: relative;
                  z-index: 1;
                  color: $color1;
                  font-size: 2.5rem;
                  font-weight: bold;
                }
                .link {
                  position: relative;
                  z-index: 1;
                  display: flex;
                  align-items: center;
                  color: $color1;
                  font-size: 1.4rem;
                  font-weight: 600;
                  .icon {
                    display: flex;
                    color: $color1;
                  }
                }

                &:hover,
                &:focus {
                  background-size: 140%;
                  opacity: 0.9;
                  .icon {
                    transform: rotate(90deg);
                  }
                  @media (max-width: $breakpoint-lg) {
                    background-size: 155%;
                  }
                }

              }
            }
          }
        }

      }
    }

  .level2.menu-type-2 {
    display: flex;
    flex-direction: row;
    .container-menu {
      display: flex;
      flex-direction: row;

      .menu-level1 {
        display: flex;
        flex-direction: column;
        flex: 7;
        margin-bottom: 4rem;

        .title {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }

        .nav {
          display: flex;
          flex-direction: row;
          line-height: 1.3;
          font-size: 1.4rem;
          font-weight: normal;
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          /*height: 100%;*/
          > li {
            > a,
            > .lien-off {
              flex-direction: column;
              justify-content: start;
              padding: 0.8rem 0;

              figure {
                transition: all .3s ease-in-out;
                overflow: hidden;
                margin-top: 0;
                img {
                  transition: all .3s ease-in-out;
                }
              }

              .label {
                font-size: 1.4rem;
                font-weight: 600;
                margin-top: 1rem;
                margin-bottom: 2rem;
                /*flex: 1 auto;*/
                @media (max-width: $breakpoint-xl) {
                  margin-bottom: 1rem;
                }
                @media (max-width: $breakpoint-lg) {
                  margin-top: 0;
                  margin-bottom: 0;
                }

              }
              @media (max-width: $breakpoint-lg) {
                figure {
                  display: none;
                }
              }
            }
            &:hover,
            &:focus {
              figure {
                img {
                  transform: scale(1.2);
                  filter: blur(.1rem);
                }
              }
              opacity: 0.9;

            }

          }

          &.nb-item-small {
            > li {
              width: 24%;
            }
          }
        }
      }

      .menu-level2 {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 auto;
        border-left: 1px solid $color13;
        padding-left: 3rem;
        margin-bottom: 4rem;
        margin-left: 3rem;

        .title {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }

        .nav {
          display: flex;
          flex-direction: column;
          line-height: 1.3;
          font-size: 1.4rem;
          font-weight: normal;
          /*height: 100%;*/
          > li {
            > a,
            > .lien-off {
              justify-content: start;
              padding: 0.8rem 0;
            }
          }
        }

        .picto {
          max-width: 21rem;
          margin-top: auto;
          margin-bottom: 0;
          margin-right: -4rem;
          margin-left: auto;
          @media (max-width: $breakpoint-xxl) {
            max-width: 18rem;
            margin-right: -3rem;
          }
          @media (max-width: $breakpoint-lg) {
            max-width: 15rem;
            margin-right: -3rem;
          }
        }
      }

      .menu-level3 {
        display: flex;
        flex-direction: column;
        flex: 1 auto;
        margin-bottom: 4rem;


        .nav {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          line-height: 1.3;
          font-size: 1.4rem;
          font-weight: normal;
          height: 100%;
          > li {
            flex: 1 0;
            > a,
            > .lien-off {
              display: flex;
              flex-direction: column;
              justify-content: end;
              align-items: start;

              background-position: center center;
              background-repeat: no-repeat;
              background-size: 110%;
              padding: 4rem;

              color: $color1;
              transition: all .2s ease-in-out;

              @media (max-width: $breakpoint-lg) {
                padding: 2rem;
              }

              &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003379+0,25add3+100&0.74+0,0.74+100 */
                background: -moz-linear-gradient(-45deg, rgba(0, 51, 121, 0.74) 0%, rgba(37, 173, 211, 0.74) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(-45deg, rgba(0, 51, 121, 0.74) 0%, rgba(37, 173, 211, 0.74) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(135deg, rgba(0, 51, 121, 0.74) 0%, rgba(37, 173, 211, 0.74) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bd003379', endColorstr='#bd25add3', GradientType=1); /* IE6-9 fallback on horizontal gradient */
              }

              .title {
                position: relative;
                z-index: 1;
                color: $color1;
                font-size: 2.5rem;
                font-weight: bold;
              }
              .link {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                color: $color1;
                font-size: 1.4rem;
                font-weight: 600;
                .icon {
                  display: flex;
                  color: $color1;
                }
              }

              &:hover,
              &:focus {
                background-size: 140%;
                opacity: 0.9;
                .icon {
                  transform: rotate(90deg);
                }

              }

            }
          }
        }
      }

    }
  }


  &-item {
    @include flex-center;
    transition: background .1s ease-in-out;
    /*position: relative;*/
    /*width: 100%;*/

    .level2 {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      transform-origin: top;
      transform: rotateX(-90deg);

      display: flex;
      flex-direction: row;
      background-color: $color1;
      padding: 2rem;
      @include box-shadow;
      z-index: 1001;
      transition: transform .1s linear;
      border-top: 2px solid $color13;

      .menu-level1 {
        .liste-images-menu {
          height: auto;
        }
      }
    }

    /*&:hover span {
      color: $color9;
    }*/

    &:hover .level2 {
      transform: rotateX(0deg);
    }

    a,
    .lien-off {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 1rem;
      color: $color8;
      &:hover,
      &:focus {
        color: $color9;
      }
    }

  }
}
/* Test Obfuscation  */
/*.lien-off,
.lien-off:hover,
  .lien-off * {
  color: blue !important;
}*/

/* overlay */
body {
  &.overlay  {
    &::before{
      content:'';
      position: fixed;
      z-index: 3;
      top: 0;
      bottom:0;
      left:0;
      right: 0;
      background-color: rgba(0,0,0,0.3);
      transition: all 0.6s ease-in-out;

    }
    #cd-main {
      #contenu {
        filter: blur(0.6rem);
        transition: all 0.6s ease-in-out;

      }
    }
  }
  &.no-overlay  {
    &::before{
      content:'';
      position: fixed;
      z-index: 3;
      top: 0;
      bottom:0;
      left:0;
      right: 0;
      background-color: rgba(0,0,0,0);
      transition: all 0.6s ease-in-out;

    }
    #cd-main {
      #contenu {
        filter: none; // blur(0) fait planté sticky histoire
        transition: all 0.6s ease-in-out;

      }
    }
    &.hide-overlay {
      &::before {
        display: none;
      }
    }
  }

}

/* Sticky */

#header.sticky .header-content {
  height: 11rem;
  @media (max-width: $breakpoint-md) {
    height: 5rem;
  }
}
#header.sticky .menu .level1 > .menu-item > a,
#header.sticky .menu .level1 > .menu-item > .lien-off {
  height: 3.7rem;
}

#header.sticky .header-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background-color: $color1;
  z-index: 1001;
  border-bottom: none;
  box-shadow: 0 .2rem 0.5rem 0 rgba(0, 0, 0, .1);

  .logo {
    margin-left: 1rem;
    width: 10rem;
  }

  .right-col {
    margin-left: 2rem;
    .access {
      .container-bloc {
        .menu-access-header {
          .menu-acces {
            .level1 {
              .acces-menu-item {
                > a {
                  padding: 0.8rem 1rem;
                }
              }
            }
          }
        }
      }
    }

    .menu {

    }
  }

}

#header.sticky .menu .level1 > .menu-item {
  padding-bottom: 0;
  padding-top: 0;
}


.kpicto-01 {
  position: absolute;
  z-index: 1;
  left: -11rem;
  top: 15rem;
  font-size: 80rem;
  color: $color16;
  opacity: 0.1;
  @media (max-width: $breakpoint-md) {
    font-size: 60rem;
  }
  @media (max-width: $breakpoint-xs) {
    font-size: 50rem;
  }
}
.contenu-z {
  position: relative;
  z-index: 2;
}

/* acces rapide sticky */
.acces-rapide {
  position: fixed;
  top: 30rem;
  right: 0;

  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-md) {
    display: none;
  }

  > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      position: relative;
      margin-bottom: 0.8rem;
      margin-left: auto;
      margin-right: 0;

      a,
      .lien-off {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: all 0.1s ease-in-out;

        padding: 1rem;
        background-color: $color15;
        color: $color1;
        border-radius: 1rem 0 0 1rem;
        .icon {
          display: flex;
          position: relative;
          top: 0;
          font-size: 2rem;
          margin-bottom: 0.5rem;
          transition: all 0.2s ease-in-out;
        }
        .text {
          max-width: 5rem;
          text-align: center;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1.1;
        }
        &.devis {
          background-color: $color9;
        }

        &.phone {
          background-color: $color10;
        }

        &:hover,
        &:focus {
          padding-right: 2rem;
          transform: scale(1.1);
        }

      }

      .bloc-rappeler {
        width: 27rem;
        height: 29rem;
        position: absolute;
        top: 0.7rem;
        right: 100%;
        overflow: hidden;
        transition: all .2s ease-in-out;
        &.hide {
          width: 0;
          height: 0;
        }

        .bloc-rappeler-content {
          padding: 1.5rem 1.5rem .1rem 1.5rem;
          background-color: #3d3d3d;
          color: #fff;

          .close {
            position: absolute;
            right: 0;
            top: 0rem;

            display: block;
            padding: .4rem;
            background-color: #515151;
            color: #fff;
            font-size: 10px;
            .text {
              display: none;
            }
          }

          .bloc-contact-rapide {
            .col-button {
              .input-effect {
                text-align: center;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

  }


}


/* style générique picto Séma */
.bg-picto {
  background-repeat: no-repeat;

  &.bg-picto-right {
    background-image:url('#{$base-img-url}/logo4.svg');
    background-position: 107% -24vw;
    background-size: 40% auto;
  }
  &.bg-picto-left {
    background-image:url('#{$base-img-url}/logo5.svg');
    background-position: -24% -2vw;
    background-size: 40% auto;
  }
}

@media (max-width: $breakpoint-lg) {
}

@media (max-width: $breakpoint-md) {
  .menu {
    display: none;
  }
  .right-col {
    width: auto;
  }
}

@media (max-width: $breakpoint-sm) {
}

@media (max-width: $breakpoint-xs) {

}

