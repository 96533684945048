/* /!\ Completer les couleurs dans "Visualiser" et "Variables" */
/* Couleur pour visualiser rapidement */

$color1: #fff;
$color2: #000;
$color3: #ddd;
$color4: #ccc;
$color5: #999;
$color6: #666;
$color7: #333;

$color8: #2d2d2d; //Couleur principale
$color9: #0099c7; //Couleur secondaire
$color10: #df4a00;
$color11: #a9c741;
$color12: #fabb04;
$color13: #E6E8EB;
$color14: #e8f0f5;
$color15: #929daa;
$color16: #17a2cc;
$color17: #74c7e0;
$color18: #5a5a5a;
$color19: #c1c7cf;
$color20: #a7c540;
$color21: #bec4cc;
$color22: #91b320;

/* Variables couleurs */
$colors: (
        "1": #fff,
        "2": #000,
        "3": #ddd,
        "4": #ccc,
        "5": #999,
        "6": #666,
        "7": #333,

        "8": #2d2d2d,
        "9": #0099c7,
        "10": #df4a00,
        "11": #a9c741,
        "12": #fabb04,
        "13": #cdd2d8,
        "14": #e8f0f5,
        "15": #929daa,
        "16": #17a2cc,
        "17": #74c7e0,
        "18": #5a5a5a,
        "19": #c1c7cf,
        "20": #a7c540,
        "21": #bec4cc,
        "22": #91b320,
);

/* desactive pour le backo */
/* body:not([class*="breakpoint"]) {} */
body,
#cms .content {

  /* Genere automatiquement les styles generiques du projet en fonction des couleurs */
  @each $number-color, $color in $colors {

    /* color */
    .color#{$number-color},
    .color#{$number-color} a {
      color: $color;
    }

    /* background-color */
    .bg#{$number-color} {
      background-color: $color;
    }

    /* border color */
    .border#{$number-color} {
      border: .1rem solid $color;
    }

    /* separator color */
    .sep#{$number-color} {
      margin: 1.5rem 0;
      height: .1rem;
      background-color: $color;
    }

    /* button generique */
    /* Si blanc */
    @if ($color == $color1 or $color == $color4 or $color == $color5) {
      .button.button#{$number-color} {
        background-color: $color;
        color: $color7;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: #333;
          color: $color;
          border: .1rem solid $color;
        }
      }
    } @else {
      .button.button#{$number-color} {
        background-color: $color;
        color: $color1;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: $color1;
          color: $color;
          border: .1rem solid $color;
        }
      }
    }

  }
}


