// Default Variables
$slick-loader-path: "#{$base-img-url}/" !default;


/* Slider */
.slick-slider {
  padding-left: 0;
}

.slick-loading .slick-list {
  background: none;
}

.slick-track {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}

.slick-slide {
  height: inherit;
}

/* Slick slider arrows + dots */
.slick-prev,
.slick-next {
  width: 3rem;
  height: 3rem;
  z-index: 1;
}

.slick-next::before,
.slick-prev::before {
  font-family: kfont !important;
  color: $color9;
  transition: all .3s ease-in-out;
  font-size: 3rem;
}

.slick-dotted.slick-slider {
  margin-bottom: 4rem;
}

.slick-dotted .slick-dots {
  line-height: 0;

  li {
    width: 1.3rem;
    height: 1.3rem;
    margin: 2px 5px;

    button {
      width: 1.3rem;
      height: 1.3rem;

      &::before {
        content: "";
        font-family: Sans-serif !important;
        background-color: $color9;
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
      }
    }
  }
}

.img-article-c {
  .slider-single-news {
    .slick-prev {
      left: 0.5rem;
    }
    .slick-next {
      right: 2rem;
    }
    button {
      &::before {
        background-color: transparent;
        color: $color1;
        text-shadow: 0 0 .4rem rgba(0,0,0,0.6);
      }
    }
  }
}
.page-realisation {
  .slider-single-news {
    .slick-prev {
      left: calc(50% - 23rem);
      @media (max-width: $breakpoint-md) {
        left: calc(50% - 19rem);
      }
    }
    .slick-next {
      right: calc(50% - 22rem);
      @media (max-width: $breakpoint-md) {
        right: calc(50% - 18rem);
      }
    }
    button {
      &::before {
        background-color: transparent;
        color: $color1;
        text-shadow: 0 0 .4rem rgba(0,0,0,0.6);
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .slick-prev {
    left: -4rem;
  }
  .slick-next {
    right: -4rem;
  }
}

@media (max-width: $breakpoint-md) {
  .slick-dots {
    right: auto;
    bottom: -1.5rem;
  }
}