.page-recrutement {

  .section-recrutement {
    padding-top: 6rem;
    .offre {
      margin-bottom: 2.5rem;
      margin-top: 2.5rem;
      border: solid 1px $color13;
      border-bottom: solid 2px $color13;
      border-radius: 2rem 2rem 2rem 2rem;
      cursor: pointer;

      max-height: 200rem;
      transition: all 200ms ease-in-out;

      &.min {
        max-height: 14.8rem;
        overflow: hidden;
        border-radius: 2rem 2rem 0 0;

        @media (max-width: $breakpoint-lg) {
          max-height: 16.5rem;
        }

        .offre-description {
          .icon {
            transform: rotateX(180deg);
          }
        }
      }

      .offre-header {
        background-color: rgba(230,240,245, .6);
        padding: 3rem;
        border-radius: 2rem 0 0 2rem;

        .title {
          text-align: center;
        }

        .body {
          .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            margin-top: 2rem;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;

            .icon {
              display: inline-flex;
              font-size: 2.5rem;
              margin-right: .5rem;
            }
          }
        }
      }


      .offre-description {
        position: relative;
        padding: 3rem 6rem;
        @media (max-width: $breakpoint-lg) {
          padding: 3rem 3rem;
        }

        .icon {
          display: flex;
          position: absolute;
          right: 2rem;
          top: 1rem;
          transform: rotateX(0);
          /*transform-origin: center center;*/
          transition: all 200ms ease-in-out;
          font-size: 1rem;
        }
      }
    }
  }

}

#form_recrutement {
  margin-top: 4rem;

  input {
    border: solid 1px $color1;
  }
  input[type="file"] {
    font-size: 1.2rem;
  }
  .label-title {
    display: block;
    margin-top: 1rem;
  }
}

@media (max-width: $breakpoint-sm) {
  .offre-header {
    order: 2;
  }
  .offre-description {
    order: 1;
  }
}