.page-realisation {
  #contenu {
    .container {
      max-width: $breakpoint-lg;
    }
  }
}

.select2-container--default.select2-container--disabled .select2-selection--multiple{
  cursor: not-allowed;
}

.no-realisations{
    font-weight: bold;
    text-align: center;
    font-size: 17px;
  display: block;
}

.container-realisations {
  padding-top: 6rem;
  padding-bottom: 6rem;

  &.bg-picto {
    .bg-picto-left {
      background-position: -24% 2vw;
    }

  }

  .list-realisations {
    list-style: none;
    padding-left: 0;


      .reference-item {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 4rem;

        .bloc-img {
          position: relative;
          figure {
            border-radius: 1rem;
            overflow: hidden;
            background-color: $color1;
            img {
              transition: all .2s ease-in-out;
            }
          }

          .label-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
          }

        }

        .bloc-body {
          .title {
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 2rem;
            color: $color8;
            font-weight: bold;
            .link {
              color: $color8;
            }
          }
          .ville {
            font-size: 1.4rem;
            color: $color9;
            margin-top: .8rem;
            margin-bottom: .8rem;

            .link {
              color: $color9;
              .icon {
                font-size: 1.6rem;
                transition: all .2s ease-in-out;
              }
            }
          }
          .desc {
            color: $color18;
            font-size: 1.4rem;
            @include line-clamp(3);
          }
        }

        &:hover,
        &:focus {
          .bloc-img {
            figure {
              img {
                transform: scale(1.2);
                filter: blur(0.1rem);
              }
            }
          }

          .bloc-body {

            .ville {
              .link {
                .icon {
                  transform: scale(1.5);
                }
              }
            }
          }

        }


      }

  }
}



.label-content {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0;

  .link-label {
    display: inline-flex;
    border-radius: 1.3rem;
    background-color: $color15;
    color: $color1;
    font-size: 1rem;
    text-transform: uppercase;
    padding: .4rem 1rem;
    margin: 0.2rem 0.4rem;
    &:hover, &:focus {
      background-color: $color18;
    }
    .item-label {

    }
  }

  .list-filtres {
    margin-left: 0;
    margin-right: 0;
    padding-right: 1rem;
    .link-label {
      background-color: $color9;
      &:hover, &:focus {
        background-color: $color15;
      }

    }
  }
  .list-tags {
    margin-left: 0;
    margin-right: auto;
    padding-right: 1rem;
    .link-label {
      background-color: $color22;
      &:hover, &:focus {
        background-color: $color15;
      }

    }

  }

  .list-etoiles {
    margin-left: auto;
    margin-right: 0;
    min-width: 8rem;
    text-align: right;

    .link-label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2.7rem;
      height: 2.7rem;
      border-radius: 50%;

      background-color: $color10;
      &:hover, &:focus {
        background-color: $color15;
      }

    }
  }
}


.page-realisation {
  .article-content {
    margin-top: 6rem;

    .item-2 {
      margin-bottom: -4rem;
      @media (max-width: $breakpoint-md) {
        margin-bottom: -2rem;
      }
      .img-article-c,
      .slider,
      .slick-list,
      .slick-track,
      .slick-slide,
      .slick-slide div,
      .slider-nav-container,
      .slider-nav-content {
        height: 100% !important;
      }
      .img-article-c {

        .label-content {
          position: absolute;
          z-index: 1;
          top: 1rem;
          left: auto;
          right: 2rem;
        }
        .slider {
          .slider-nav-content {
            figure {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .title {
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: left;
      .mini {
        font-size: 1.3rem;
        @media (max-width: $breakpoint-md) {
          font-size: 1.2rem;
        }
      }
    }
    .lieux-content {
      margin-bottom: 2rem;
      color: $color9;
      text-transform: uppercase;
      font-size: 2rem;
      .icon {
        font-size: 2.5rem;
        margin-right: 0.2rem;
      }
    }
    .article-txt {
      margin-top: 2rem;
      margin-bottom: 6rem;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 2rem;
      }
    }

  }
}

