.page-catalogue {
  .img-category-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  .h1 {
    font-size: 2.9rem;
    margin-bottom: 2rem;
  }

  .h2 {
    text-align: center;
    font-size: 2.8rem;
  }

  .desc-category-container {
    max-width: 80rem;
    text-align: center;
    color: $color9;
    line-height: 1.8;
    margin-left: auto;
    margin-right: auto;
  }
}

.listing-catalogue-c {
  padding-top: 4rem;
  padding-bottom: 7rem;
  margin-top: 3rem;
  background-color: $color4;
}

/* Colonne de gauche */
.filtres-col {
  .h2 {
    color: $color10;
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1.5rem;
    margin-left: -1rem;
  }

  label {
    cursor: pointer;
  }

  hr {
    display: block;
    background-color: $color10;
    height: .1rem;
    width: calc(100% + 1rem);
    margin-left: -1rem;
    margin-top: 0;
    margin-bottom: 0;
    border: none;

    &.light {
      background-color: $color11;
      margin-bottom: .5rem;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 1rem;
  }

  .filter-selection {
    input {
      margin-right: .5rem;
    }

    label {
      color: $color9;
    }

    input:checked + label {
      color: $color8;
    }
  }

  .filter-libelle-c {
    @include flex-between;
    padding-top: 1rem;
    padding-bottom: .5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $color8;
    font-weight: 600;
    cursor: pointer;
    transition: color .3s ease-in-out;

    .icon {
      font-size: 2.5rem;
      margin-right: .5rem;
    }

    &:hover {
      color: $color9;
    }
  }

  .collapse-block.see-more {
    display: block;
    cursor: pointer;
    transition: transform .3s ease-in-out;

    > span {
      display: block;
      width: 1.3rem;
      height: .1rem;
      background-color: $color4;
    }
  }

  .filter-form-c {
    height: auto;
    overflow: hidden;

    .filter-form {
      input {
        margin-left: 1.5rem;
      }

      label {
        font-size: 1.4rem;
        padding-left: .3rem;
      }
    }
  }
}

.filter-block.closed {
  .see-more {
    transform: rotate(90deg);
  }

  .filter-form-c {
    height: 0;
  }
}

/* Colonne de droite */
.products-list-col {
  .header-products-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color1;
    padding: 1.1rem 1rem 1.1rem 3.3rem;
    margin-bottom: 4rem;
    font-size: 1.4rem;

    .nb-products {
      font-weight: 600;
    }

    .orga-products {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55rem;

      label {
        font-weight: 600;
        margin-bottom: 0;
      }

      .select-c {
        position: relative;
        cursor: pointer;
        padding-right: 2rem;

        select {
          color: $color6;
          border: none;
          appearance: none;
          outline: 0;
          cursor: pointer;
          padding: 0 .5rem;
        }

        &::after {
          content: "\EA12";
          display: block;
          position: absolute;
          top: .3rem;
          right: 1rem;
          font-family: kfont;
          font-size: 1rem;
        }
      }

      .icon {
        font-size: 2.8rem;
        line-height: 1;
        color: $color11;

        &.current {
          color: $color6;
        }

        + .icon {
          margin-left: .8rem;
        }
      }
    }
  }

  &.grid-list {
    .products-list-line {
      display: none;
    }
  }

  &.table-list {
    .products-list-line {
      display: block;
    }

    .products-liste-card {
      display: none;
    }
  }
}

/* Pagination */
.pagination-catalogue {
  margin-top: 2rem;
  text-align: center;

  .count {
    font-size: 1.8rem;
    color: $color6;
  }

  .progress-bar-catalogue {
    position: relative;
    height: .4rem;
    width: 23rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    border: .1rem solid $color9;

    .progress-status {
      position: absolute;
      top: -.1rem;
      left: -.1rem;
      background-color: $color6;
      height: .4rem;
    }
  }

  .button {
    padding: 1.6rem 7.7rem;
    margin-top: 3.5rem;
  }
}

@media (max-width: $breakpoint-xl) {
  .filtres-col .filter-form-c .filter-form input {
    margin-left: .5rem;
  }
  .products-liste-card {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .card-product {
    width: calc(100% / 3 - 1rem);
  }
}

@media (max-width: $breakpoint-md) {
  .card-product {
    width: calc(100% / 2 - 1rem);
  }
  .products-list-col .header-products-list {
    flex-wrap: wrap;

    .nb-products {
      order: 2;
    }
  }
  .products-list-line .product-line {
    flex-wrap: wrap;

    .product-name {
      width: calc(100% - 12rem);
    }

    .prix {
      order: 4;
    }

    .add-cart {
      order: 5;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .img-category-container-col {
    order: 2;
    margin-top: 2.5rem;
  }
  .products-list-col .header-products-list {
    padding-left: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .products-list-col .header-products-list {
    .nb-products {
      position: absolute;
      right: 3rem;
    }

    .orga-products {
      flex-direction: column;
      align-items: flex-start;

      > div {
        margin-bottom: 1rem;
      }
    }
  }
  .card-product {
    width: 100%;
  }
  .products-list-line .product-line {
    .stock {
      margin-top: .6rem;
      margin-bottom: .6rem;
    }

    .add-cart {
      width: 100%;
    }
  }
}