.products-liste-card {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-product {
  position: relative;
  width: calc(25% - 2rem);
  overflow: hidden;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: $color1;
  box-shadow: 0 .6rem .5rem 0 #dfdfdf;
  display: flex;
  flex-direction: column;

  .img-block {
    position: relative;
    order: 1;
    min-height: 280px;

    a {
      img {
        height: 100%;
        width: 100%;
        padding: 1rem;
        object-fit: contain;
      }
    }

    .flag-product {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }

  .infos-product {
    order: 2;
    height: 20rem;
    padding: 1.8rem;
    color: $color8;
    text-align: center;
    background-color: $color1;
    transition: transform .3s ease-in-out;

    .product-name {
      height: 8rem;
      overflow: hidden;

      a {
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $color8;
      }
    }

    .price {
      font-size: 3rem;
      margin-bottom: .8rem;
      color: $color6;

      .old-price {
        display: inline-block;
        color: $color9;
        font-size: 1.7rem;
        margin-left: 1.2rem;

        &::after {
          content: "";
          display: block;
          height: .1rem;
          margin-top: -1.3rem;
          background-color: $color9;
        }
      }

      .price-tax {
        font-size: 1.2rem;
      }
    }

    .add-cart {
      margin-top: 3.5rem;
    }
  }

  &:hover .infos-product {
    transform: translateY(-8.4rem);
  }
}

.flag-product {
  padding: .4rem 1.4rem;
  font-size: 1.1rem;
  font-weight: 700;
  color: $color1;
  text-transform: uppercase;
  background-color: $color8;
}


.label-product {
  position: absolute;
  top: .7rem;
  right: 1rem;
  display: flex;
  flex-direction: column;

  img {
    width: 3.8rem;

    + img {
      margin-top: .6rem;
    }
  }
}

.add-cart .button {
  color: $color1;
  border-color: $color8;
  padding: 1rem 0;
  text-align: center;
  width: 100%;

  .icon {
    color: $color1;
    font-size: 2.5rem;
    margin-right: 3rem;
    transition: color .3s ease-in-out;
  }

  &:focus,
  &:hover {
    opacity: 1;
    color: $color8;
    background-color: $color1;

    .icon {
      color: $color8;
    }
  }
}

.old-price {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -.2rem;
    left: -.2rem;
    border-top: 1px solid $color8;
  }
}

/* Product list line */
.products-list-line .product-line {
  @include flex-between;

  .product-img-line {
    width: 10rem;
    height: 10rem;
  }

  .product-name {
    width: calc(100% - 50rem);
    padding-left: .8rem;
    line-height: 1.2;

    a {
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 700;
      color: $color8;
      margin-bottom: .6rem;

      &:hover {
        color: $color9;
      }
    }

    .ref-product-line {
      font-size: 1.4rem;
      color: $color9;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .add-cart .button {
    width: 20rem;
  }
}

@media (max-width: $breakpoint-xl) {
  .card-product {
    width: calc(25% - 1rem);
    margin-right: .5rem;
    margin-left: .5rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .add-cart .button .icon {
    margin-right: 1rem;
  }
  .card-product .infos-product {
    padding: 1.8rem;
  }
}

@media (max-width: $breakpoint-md) {
  .card-product .infos-product {
    padding: 1.8rem .5rem;
  }
}