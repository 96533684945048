.page-lieux {
  .body-lieux-content {
    margin-top: 6rem;
    margin-bottom: 0;

    .title {
      margin: 2rem 0 2rem 0;
    }
    ul {
      list-style: none;
      padding-left: 3rem;
      margin-bottom: 2rem;
      li {

      }
    }
    .sep {
      margin: 3rem 0;
    }

    /* Liste */
    .lieux-liste {
      ul {
        li {
          margin: 0.5rem 0;
          a {
            &.lieux-content {
              display: flex;
              flex-direction: row;
              align-items: start;
              .icon {
                font-size: 2.5rem;
                margin-right: 1rem;
              }
              .lieux-desc {
                display: flex;
                flex-direction: column;
                .title2 {
                  text-transform: uppercase;
                }
                .count {
                  color: $color8;
                  font-size: 1.2rem;
                }

              }
            }
          }
        }
      }
    }

  }
}

