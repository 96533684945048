.page-cms,
.liste-content {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @include title-global;
  }

  h1,
  .h1 {
    @include h1;
  }

  h2,
  .h2 {
    @include h2;
  }

  h3,
  .h3 {
    @include h3;
  }

  h4,
  .h4 {
    @include h4;
  }
}

.sous-titre {
  @include sous-titre;
}


@import 'components/titles_spe.scss';
