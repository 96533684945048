.textcenter {
  text-align: center;
}

.textright {
  text-align: right;
}

.collapse-box {
  margin-bottom: 4rem;

  .collapse-item {
    margin: 1rem auto 1rem 2.5rem
  }
}

.min-height {
  min-height: 60rem;
  @include flex-center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mw-1000 {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
}

.bold {
  font-weight: 700;
}

.small {
  font-size: 80%;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.grey-block {
  background-color: $color5;
  padding: 2rem;
}

/* Toast */
.alert-container .alert {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: $color1;

  &.alert-danger {
    background-color: #f35958;
  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
  }
}

/* Bloc quantité */
.qt-block {
  display: flex;
  align-items: center;
  text-align: center;

  .qt-manage,
  .input-qty-cart {
    display: inline-block;
    height: 4.8rem;
    width: 2rem;
    border: .1rem solid $color4;
  }

  input.input-qty-cart {
    position: relative;
    width: 6rem;
    text-align: center;
    font-size: 2.2rem;
    padding-left: 0;
    padding-bottom: .4rem;
  }

  .qt-manage {
    @include flex-center;
    width: 3rem;
    color: $color8;
    background-color: $color1;
    font-size: 3.5rem;
    font-weight: 400;
    cursor: pointer;
    transition: color .3s ease-in-out;

    &:hover {
      color: $color9;
    }

    &.less-qt {
      margin-right: -.1rem;

      span {
        font-size: 4rem;
        margin-top: -.8rem;
      }
    }

    &.more-qt {
      margin-left: -.1rem;
    }
  }
}

/* Pastille stock */
.stock {
  @include flex-center;
  font-size: 1.4rem;
  font-weight: 700;
  color: $color6;

  > span {
    line-height: 0;
  }

  .icon-stock {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 1rem;
    background-color: #7EBC00;
    border-radius: 50%;

    &.icon-outstock {
      background-color: #B20C0C;
    }
  }
}

/* Prix barré */
.prixbarre {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: .1rem;
    background-color: #333;
  }
}

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: contain;
}
.ratio.cover > * {
  object-fit: cover;
  background-size: cover;
}

/* cf mixin.scss */
.absolute-container {
  @include absolute-container;
}

.flex-center {
  @include flex-center;
}

.flex-between {
  @include flex-between;
}

.flex-around {
  @include flex-around;
}

.cover-img {
  @include cover-img;
}

.box-shadow {
  @include box-shadow;
}

.box-shadow-light {
  @include box-shadow-light;
}

/* chemin img */
/* # {$base-img-url} */
$base-img-url: "../img/theme";

/*@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: $breakpoint-xl;
  }
}
@media (min-width: $breakpoint-xxl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: $breakpoint-xxl;
  }
}*/


@media (max-width: $breakpoint-sm) {
  /*.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 1rem;
    padding-left: 1rem;
  }*/
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
