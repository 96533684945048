//@font-face {
//  font-family: 'Poppins';
//  src: local('Poppins Light'), local('Poppins-Light'),
//  url('../fonts/subset-Poppins-Light.woff2') format('woff2'),
//  url('../fonts/subset-Poppins-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}
/* desactive pour le backo */
/*
html body:not([class*="breakpoint"]) {

  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .pressstyle {
    font-family: 'Press Style', fantasy, sans-serif;
  }

}
*/


@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url('../fonts/subset-OpenSans-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), local('OpenSans'),
  url('../fonts/subset-OpenSans.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/subset-OpenSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html body:not([class*="breakpoint"]) {

  .opensans {
    font-family: 'Open Sans', sans-serif;
  }

}