#cms .content .button,
.button {
  display: inline-block;
  color: $color1;
  background-color: $color8;
  border: .1rem solid $color8;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.2rem 3.5rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: all .3s ease-in-out;

  &:hover,
  &:focus {
    color: $color8;
    background-color: $color1;
  }

  &.button-small {
    font-size: 1rem;
    padding: .6rem 1.2rem;
  }

  &.button-big {
    font-size: 1.8rem;
    padding: 2.4rem 5rem;
  }

  &.button-outline {
    color: $color8;
    background-color: $color1;

    &:hover,
    &:focus {
      color: $color1;
      background-color: $color8;
    }
  }
  &.button-radius {
    border-radius: 3rem;
  }
  &.button-outline-2 {
    color: $color9;
    border: .1rem solid $color9;
    background-color: transparent;

    text-transform: none;
    border-radius: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &:hover,
    &:focus {
      color: $color1;
      border: .1rem solid $color9;
      background-color: $color9;
    }
  }
  &.button-outline-3 {
    font-size: 1.6rem;
    color: $color10;
    border: none;
    background-color: $color1;

    text-transform: none;
    border-radius: 3rem;

    &:hover,
    &:focus {
      color: $color1;
      border: none;
      background-color: $color10;
    }
  }

  &.button-big-2 {
    min-height: 5.5rem;
    font-size: 1.6rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 3rem;
      margin-right: 1rem;
      position: relative;
      left: 0;
      transition: all 0.3s ease-in-out;

    }
    &:hover {
      .icon {
        left: -0.2rem;
        transform: scale(1.2);
      }
    }
  }

}
#cms .content .button,
.button {
  &.button-big-2 {
    min-height: 2.5rem;
  }
}
