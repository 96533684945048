.catalogue-light-produit {
  color: $color18;
  font-size: 1.4rem;

  .banner {
    min-height: 15rem;

    .banner-product {
      display: flex;
      flex-direction: column;


      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003d80+0,20b0d5+100&0.8+0,0.8+100 */
        background: -moz-linear-gradient(left,  rgba(0,61,128,0.8) 0%, rgba(32,176,213,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,61,128,0.8) 0%,rgba(32,176,213,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,61,128,0.8) 0%,rgba(32,176,213,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc003d80', endColorstr='#cc20b0d5',GradientType=1 ); /* IE6-9 */

      }

      .container-breadcrumb {
        z-index: 1;

        margin-top: 0;
        margin-bottom: 0;
      }
      .title {
        position: relative;
        z-index: 1;

        margin: auto;
        color: $color1;
        text-align: center;

        .mini-title {
          display: flex;
          color: #fff; /* #ffef00 */
          font-size: 1.3rem;
          margin-top: 0.5rem;
        }

      }
      // var cercle activité
      $dim-cercle: 16rem;

      .img-content {
        position: absolute;
        z-index: 1;
        right: 15rem;
        bottom: -7rem;

        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: $dim-cercle;
        height: $dim-cercle;
        border-radius: 50%;
        background-color: $color1;
        margin-top: 0;
        margin-bottom: 0;

        @media (max-width: $breakpoint-lg) {
          transform: scale(0.8);
          right: 8rem;
        }
        @media (max-width: $breakpoint-md) {
          transform: scale(0.6);
          right: 1rem;
        }
        @media (max-width: $breakpoint-sm) {
          transform: scale(0.4);
          right: 0;
        }
        @media (max-width: $breakpoint-sm) {
          display: none;
        }

        .img-pattern {
          position: absolute;
          right: 0;
          bottom: 0;

          left: -1rem;
          top: -1rem;

          overflow: hidden;
          width: calc(100% + 2rem);
          height: calc(50% + 2rem);
          /*background-color: rgba(100,0,0,0.3);*/

          &:before {
            content:'';
            position: absolute;
            left: 1rem;
            top: 1rem;

            width: $dim-cercle;
            height: $dim-cercle;

            border-radius: 50%;
            box-shadow: 0 0 .6rem .1rem rgba(0,0,0,0.2);
            /*background-color: rgba(0,100,0,0.3);*/
          }

        }
        img {
          position: relative;
          left: 0;
          top: 0;
          transition: all 0.3s ease-in-out;
        }

      }

      /*
      .banner-picto {
        position: absolute;
        z-index: 1;
        right: 15rem;
        bottom: -5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 15rem;
        height: 15rem;
        background-color: $color1;
        border-radius: 50%;
      }
      */
    }
  }

  .item-margin {
    margin: 8rem 0 4rem 0;
    @media (max-width: $breakpoint-md) {
      margin-top: 4rem;
    }
    &.item2 {
      margin-bottom: 0;
    }
  }

  .item-padding {
    padding: 6rem 0 6rem 0;
  }
  .padding-right {
    padding-right: 4rem;
    @media (max-width: $breakpoint-lg) {
      padding-right: 1.5rem;
    }
  }
  .padding-left {
    padding-left: 4rem;
    @media (max-width: $breakpoint-lg) {
      padding-left: 1.5rem;
    }
  }

  .full-item {
    /*background-color: $color14;*/
    padding-top: 4rem;
    padding-bottom: 4rem;

    .item-img-full {
      margin-top: -4rem;
      margin-bottom: auto;

      &.col-lg-6 {
        width: 65%;
        margin-left: -15%;
        @media (max-width: $breakpoint-md) {
          width: 100%;
          margin-left: 0;
        }
      }

      figure {
        position: relative;
        right: -6vw;

        @media (max-width: $breakpoint-xxxl) {
          right: -14vw;
        }
        @media (max-width: $breakpoint-xxl) {
          right: -8vw;
        }
        @media (max-width: $breakpoint-xl) {
          right: -9vw;
        }
        @media (max-width: $breakpoint-lg) {
          right: -10vw;
        }
        @media (max-width: $breakpoint-md) {
          right: -14vw;
        }
        @media (max-width: $breakpoint-sm) {
          right: -6vw;
        }
        @media (max-width: $breakpoint-xs) {
          right: -4vw;
        }
      }
    }
  }

  h2, .h2 {
    Margin-bottom: 1.5rem;
  }
  .item-content {
    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin: 0.5rem 0;
        &::before {
          font-family: "kfont";
          content: "\EA16";
          color: $color9;
          font-size: 0.9rem;
          margin-right: 0.5rem;
        }
      }
    }
  }


  .bloc-produit-faq {
    .item1 {
      .item-content {

      }
      .item-faq {
        .liste-faq {
          margin-top: 0;
        }
      }
    }
  }

  .bloc-produit-reassurance {
    .container {
      max-width: $breakpoint-lg;
    }
    /*font-size: 1.4rem;*/
    header {
      text-align: center;
      margin-bottom: 6rem;
      .mini {
        font-size: 100%;
      }
    }
    .item-list {
      .item-content {
        display: flex;
        flex-direction: row;

        .picto {
          margin-top: -2rem;
          margin-right: 2rem;
        }
        .bloc-content {
          flex: 1;
          h1, h2, h3, h4, h5, h6 {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: .8rem;
          }
        }
      }
    }
  }



  .bloc-produit-projet {

    background-repeat: no-repeat;
    background-image:url('#{$base-img-url}/bg-projet.webp');
    background-position: center;
    background-size: cover;

    color: $color1;

    .container-liste-item {
      max-width: $breakpoint-xl;
      margin-left: auto;
      margin-right: auto;
    }

    header {
      text-align: center;
      margin-bottom: 6rem;
      h1, h2 {
        /*font-size: 3.2rem;*/
        font-weight: bold;
        color: $color1;
      }
      h3, h4, h5, h6 {
        font-size: 2rem;
        margin-bottom: 2rem;
        color: $color1;
      }
    }

    /* Slider projet */
    .list-item-projet-bloc {
      .slick-slide {

        &:first-child {
          .slider-nav-container {
            &::before {
              display: none;
            }
          }
        }

        .slider-nav-container {
          position: relative;

          &::before {
            position: absolute;
            right: 10rem;
            left: 0;
            top: 0;
            height: 5rem;
            transform: translateX(-50%);
            overflow: hidden;

            display: flex;
            justify-content: end;
            align-items: center;

            font-family: "kfont";
            content: '\EA54';
            font-size: .9rem;
          }

          .item {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            figure {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              width: 5rem;
              height: 5rem;
              border-radius: 50%;

              background-color: $color19;
              img {
                width: 3rem;
                height: 3rem;
              }
            }
            .title {
              margin-top: .3rem;
              font-size: 1.4rem;
              text-align: center;
            }
          }
        }
        &.slick-current {
          .slider-nav-container {
            .item {
              figure {
                background-color: $color11;
              }
              .title {
                color: $color11;
              }
            }
          }
        }
      }
    }

    .list-item-projet-list {

      .slick-list {
        height: auto;
        .slick-track {

          .slick-slide {
            > div {
              height: 100%;
              .slider-nav-container {
                height: 100%;
                .item {
                  height: 100%;
                  .content {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }


      .slick-slide {
        margin-left: 2rem;
        margin-right: 2rem;


        .slider-nav-container {
          padding-top: 4rem;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $color1;
            position: relative;
            padding: 4rem;

            @media (max-width: $breakpoint-md) {
              padding: 2rem;
            }


            figure {
              position: absolute;
              left: -3rem;
              top: -3rem;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              width: 6rem;
              height: 6rem;
              border-radius: 50%;

              background-color: $color19;
              img {
                width: 3.5rem;
                height: 3.5rem;
              }
            }
            .content {
              margin-top: .3rem;
              font-size: 1.4rem;
              font-weight: 600;
              color: $color19;

              h1, h2, h3, h4, h5, h6 {
                color: $color19;
                margin-bottom: 1rem;
                font-weight: bold;
              }
            }
          }
        }

        &.slick-current {
          .slider-nav-container {
            .item {
              figure {
                background-color: $color11;
              }
              .content {
                color: $color18;
                h1, h2, h3, h4, h5, h6 {
                  color: $color8;
                }
              }

            }
          }
        }

      }
    }

  }

}


.bloc-slider-nav {
  .slider-nav-container {
    text-align: center;

    .slider-nav-content {
      display: flex;
      background-size: contain;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      padding: 1rem;
    }
  }

  .gallery .slick-slide > div {
    margin: .5rem;
    height: 100%;
  }

  .slider-nav,
  .slider-nav-news {
    margin-left: .2rem;

    .slick-prev {
      left: -3rem;
    }

    .slick-next {
      right: -3rem;
    }

    .slick-track {
      margin: 0 -.4rem;
      margin-top: 1rem;
      padding-bottom: 1rem;

      .slick-slide {
        padding-left: .2rem;
        margin: 0 .4rem;
        cursor: pointer;
      }

      img {
        object-fit: contain;
      }
    }
  }
}

.info-produit {
  .h1 {
    font-size: 3.2rem;
    color: $color6;
    font-weight: 800;
    text-align: left;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 0;
  }

  .reference {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: $color6;
    margin-bottom: 2rem;
  }

  .stock {
    color: $color6;
    font-weight: 600;
    justify-content: flex-start;
    margin-top: 3rem;
  }
}

.achat-produit-c {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $color4;
  padding: 3rem;

  .bloc-prix {
    .prix {
      font-size: 3.2rem;
      font-weight: 400;
    }
  }

  #panier {
    margin-top: 2.6rem;

    .qt-submit-block {
      display: flex;
      justify-content: space-between;
    }

    button[type="submit"] {
      padding: .5rem 2.5rem;
      letter-spacing: .15rem;
      font-size: 1.2rem;

      &:not(.dispo) {
        border-color: $color9;
        background-color: $color9;
        cursor: not-allowed;
      }

      .icon {
        font-size: 2.8rem;
        margin-right: 1rem;
      }

      .textaddcart, .textnondispo, .textrupture {
        display: none;
      }
    }

    button[type="submit"].dispo {
      .textaddcart {
        display: inline-block;
      }
    }

    button[type="submit"].indispo {
      .textnondispo {
        display: inline-block;
      }
    }

    button[type="submit"].rupture {
      .textrupture {
        display: inline-block;
      }
    }
  }
}

.tabs-article {
  margin-top: 5rem;
  margin-bottom: 5rem;

  .nav-tabs {
    display: flex;
    justify-content: center;
    border-top: .2rem solid $color6;
    border-bottom: .2rem solid $color6;

    .nav-item {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: $color6;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        height: 3.9rem;
        width: 28rem;
        text-align: center;
        border-radius: 0;

        &:after {
          position: absolute;
          left: 50%;
          bottom: -1.5rem;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 1rem .85rem 0 .85rem;
          border-color: transparent transparent transparent transparent;
          transition: border-color .3s ease-in;
        }

        .icon {
          font-size: 2rem;
          margin-right: 1rem;
        }

        &:focus {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          color: $color1;
          background-color: $color8;

          &::after {
            border-color: #74000a transparent transparent transparent;
          }
        }
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
  }

  .tab-pane {
    color: $color9;
  }

}

.autre-produit {
  background-color: $color4;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 8rem;

  .h2 {
    font-size: 3.2rem;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 5rem;

    .icon {
      margin-left: 1.5rem;
    }
  }

  .autre-produit-slider {
    width: calc(100% - 15rem);
    margin-right: auto;
    margin-left: auto;

    .card-product {
      width: calc(100% - 2rem);
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .slick-prev {
      left: -7rem;
    }

    .slick-next {
      right: -7rem;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .achat-produit-c {
    padding: 2rem;
  }
  .qt-submit-block {
    flex-wrap: wrap;

    button {
      margin-top: 2rem;
      width: 100%;
    }
  }
  .autre-produit .autre-produit-slider {
    width: calc(100% - 5rem);
  }
}

@media (max-width: $breakpoint-lg) {
  .autre-produit {
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 4rem;

    .autre-produit-slider {
      width: calc(100% - 2rem);
    }
  }
}

@media (max-width: $breakpoint-md) {
  .page-article #chemin {
    margin-bottom: 0;
    min-height: 3rem;
  }
  .bloc-slider-nav {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;

    .slider-nav-container {
      max-width: 30rem;
    }

    .slider-nav {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;

      .slick-track .slick-slide {
        max-width: 7rem;
      }
    }
  }
  .info-produit-c {
    order: -1;
  }
  .achat-produit-c #panier .qt-submit-block {
    .qt-block {
      width: 100%;
    }

    button {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .tabs-article .nav-tabs .nav-item {
    width: calc(100% / 3);

    .nav-link {
      width: 100%;
      font-size: 1.4rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .tabs-article .nav-tabs .nav-item .nav-link {
    line-height: 1.1;
  }
  .autre-produit .h2 {
    margin-bottom: 2.4rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .tabs-article .nav-tabs .nav-item {
    width: 100%;

    .nav-link::after {
      content: none;
    }
  }
  .autre-produit .h2 {
    font-size: 2.2rem;
  }
}