
/* Page CMS Notre histoire */
.page-cms .body_frise_historique {
  .type-texte {
    .title {
      color: $color16;
      font-weight: bold;
    }
    h2, .h2 {
      margin-bottom: 2rem;
    }
    h3, .h3 {
      color: $color16;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}


/* Page historique frise */
.cms-code-notre-histoire  #header.sticky{
  box-shadow: none;
}
.frise_container {
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
  z-index: 4;
  /*&::before{
    content: '';
    display: block;
    position: absolute;
    bottom: 1.8rem;
    left: 0;
    right: 0;
    width: 100%;
    height: .3rem;
    background: $color18;
  }*/
  &.frise_sticky {
    box-shadow: 0 .1rem 1rem 0 rgba(0,0,0,.1);
    background-color: $color1;
    /*top: 110px; data-margin-top="110" builder */
    @media (max-width: $breakpoint-md) {
      top: 5rem !important;
    }
  }

  .frise_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: $breakpoint-xs) {
      justify-content: start;
    }

    .frise_date {
      position: relative;
      /*height: 6rem;*/
      color: $color1;
      font-size: 1.4rem;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.25s ease-out;
      outline: none;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 auto;

      .t-circle {
        transition: all 0.25s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin: 0.2rem 0;

        background-color: $color19;

        @media (max-width: $breakpoint-md) {
          font-size: 1.2rem;
          width: 4rem;
          height: 4rem;
          margin: 0.2rem;
        }

        @media (max-width: $breakpoint-sm) {
          font-size: 1rem;
          width: 3rem;
          height: 3rem;
          margin: 0.2rem;

        }
      }


      &::before {
        transition: all 0.25s ease-out;
        position: absolute;
        right: 6.5rem;
        left: 0;
        top: 0;
        height: 100%;
        transform: translateX(-50%);
        overflow: hidden;

        display: flex;
        justify-content: end;
        align-items: center;

        font-family: "kfont";
        content: '\EA54';
        font-size: .9rem;
        color: $color21;

        @media (max-width: $breakpoint-md) {
          display: none;
        }
      }

      &:first-child {
          &::before {
            display: none;
          }
      }


      .t-label {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 0rem;
        width: 16rem;
        transition: all 0.20s ease-out;
        overflow: hidden;
        border-radius: .5rem;
        background-color: $color1;
        color: $color18;
        padding: 0 .5rem;
        &::after {
          content: '';
          display:block;
          padding-bottom: 0.3rem;
        }
        &::before {
          content: '';
          display:block;
          padding-top: 0.3rem;
        }
      }

      &:hover{

        .t-circle {
          background-color: $color18;
          color: $color1;
        }

        .t-label {
          max-height: 10rem;
        }
      }
      &.active{
        .t-circle {
          background-color: $color16;
          color: $color1;
        }
        &::after{
          background-color: $color1;
        }
      }
    }
  }

}




@media (max-width: $breakpoint-xs) {
  .page-cms  #contenu .body_frise_historique h1{
    margin-bottom: 2rem;
  }
}
