/* Section FAQ */


/* Faq Home */
.liste-faq {
  margin-top: 10rem;
  position: relative;
  min-height: 20rem;
  padding-bottom: 5rem;

  .collapsing {
    transition: height 0.2s ease-in-out;
    @media (max-width: $breakpoint-md) {
      transition: none;
    }
  }

    .text_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;

      ul {
        margin: 0 0 0 6rem;
        padding: 0;
        list-style: none;
        list-style-position: inside;
        width: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (max-width: $breakpoint-md) {
          margin: 0 0 0 0;
        }

        li {
          border-bottom: .1rem solid $color13;
          &.subaccordion:first-child {
            > .f-link {
              border-top: .1rem solid $color13;
              /*border-bottom: .1rem solid $color13;
              &.collapsed {
                border-bottom: 0rem solid $color1;
              }*/
            }
          }

          .f-link {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            transition: all 0.3s ease-in-out;

            padding: 1.5rem 2rem 1.5rem 0;

            font-size: 2rem;
            font-weight: bold;
            color:$color9;
            &.collapsed {
              font-weight: 600;
              color:$color8;
            }
            > .icon {
              transition: transform .3s ease-in-out;
              color:$color9;
            }
            &:not(.collapsed) > .icon {
              transform: rotate(45deg);
            }
          }

          .subaccordion_content {
            padding-left: 1rem;
            @media (max-width: $breakpoint-sm) {
              padding-left: 0;
            }
            > ul {
              > li {
                > a {
                  font-size: 1.6rem;
                }
                .faq_reponse {
                  padding-top: 2rem;
                  padding-bottom: 2rem;
                  padding-left: 2rem;
                  border-left: .3rem solid $color13;

                  @media (max-width: $breakpoint-sm) {
                    padding-left: 1rem;
                    border-left: .2rem solid $color13;
                  }
                }
              }
            }
          }



          ul {
            margin: 0;
          }

        }
      }
    }
}




@media (min-width: $breakpoint-sm) {
  #faq_question_container {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

@media (max-width: $breakpoint-sm) {
  body:not(#home) #faq_title{
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-xs) {
  #faq_question_container .faq_question_card {
      table {
        display: block;
        overflow-x: auto;
        height: auto !important;
      }
  }
}