/* Footer */
#footer {
  border-top: 2px solid $color13;

  > .contenu-z:first-child {
    z-index: 3;
  }

  .container-1 {
    @include flex-between;


    /* Access topbar */
    .access {
      /*background-color: $color1;*/
      font-size: 1.6rem;
      margin-right: 0;
      margin-left: auto;
      width: 100%;
      border-bottom: 2px solid $color13;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      height: 100%;
      @media (max-width: $breakpoint-md) {
        border-bottom: none;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 1.2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

      }

      .container-bloc {
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        color: $color8;

        .footer-logo {
          margin-left: 0;
          margin-right: auto;
          max-width: 30%;

          img {
            max-width: 16rem;
            margin-bottom: 1rem;
          }
        }


        .menu-access-footer {
          margin-right: 1rem;
          @media (max-width: $breakpoint-sm) {
            margin-right: 0;
          }

          .menu-acces {
            .level1 {
              display: flex;

              position: relative;
              margin: 0;
              padding: 0;
              list-style: none;
              font-size: 1.3rem;

              .acces-menu-item {
                position: relative;
                margin: 0 1rem;

                &:last-child {
                  margin-right: 0;
                }

                a,
                .lien-off {
                  color: $color8;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                  height: 100%;

                  [class^="icon-"],
                  [class*=" icon-"] {
                    display: flex;
                    margin-right: 0.8rem;
                    font-size: 2rem;

                    position: relative;
                    left: 0;
                    transition: all 0.3s ease-in-out;

                    @media (max-width: $breakpoint-sm) {
                      margin-right: 0;
                    }
                  }

                  &:hover,
                  &:focus {
                    color: $color9;
                    [class^="icon-"],
                    [class*=" icon-"] {
                      left: -0.1rem;
                      transform: scale(1.2);
                    }
                  }

                  &.item-devis,
                  &.item-mail {
                    border-radius: 3rem;
                    padding: 1.5rem 3rem;
                    @media (max-width: $breakpoint-sm) {
                      padding: 1rem 1rem;
                    }

                  }

                  &.item-devis {
                    background-color: $color10;
                    color: $color1;

                    &:hover,
                    &:focus {
                      background-color: $color1;
                      color: $color10;
                      box-shadow: inset 0 0 0 1px $color10;
                    }
                  }

                  &.item-mail {
                    background-color: $color9;
                    color: $color1;

                    &:hover,
                    &:focus {
                      background-color: $color1;
                      color: $color9;
                      box-shadow: inset 0 0 0 1px $color9;
                    }

                    [class^="icon-"],
                    [class*=" icon-"] {
                      font-size: 1.4rem;
                    }
                  }

                  @media (max-width: $breakpoint-lg) {
                    .text {
                      display: none;
                    }
                  }
                }

                .level2 {
                  max-height: 0;
                  transition: all .2s ease-in-out;
                  overflow: hidden;
                  position: absolute;
                  z-index: 5;
                  top: 100%;
                  right: 0;
                  background-color: $color14;
                  min-width: 18rem;
                  border-radius: 2rem 2rem 1rem 1rem;


                  margin: 0;
                  padding: 0;
                  list-style: none;

                  li {
                    padding: 0.5rem 1rem;
                    font-size: 80%;
                  }

                }

                &:hover {
                  .level2 {
                    max-height: 20rem;
                    padding: 1rem 0;
                  }
                }

              }


            }
          }
        }

      }
    }

  }
  .container-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.4rem;
    padding-top: 3rem;

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
    }

    .bloc-item {
      display: flex;
      flex-direction: column;
      @media (max-width: $breakpoint-xs) {
        order: 2;
      }

      .bloc-showroom {
        font-size: 1.4rem;
        color: $color18;
        strong {
          font-weight: 600;
          color: $color8;
        }
        @media (max-width: $breakpoint-xs) {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }

      .sociaux-footer {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        @media (max-width: $breakpoint-xs) {
          order: 1;
          margin-left: auto;
          margin-right: auto;
        }

        .icon {
          @include flex-center;
          flex-direction: row;
          font-size: 2.5rem;
          background-color: $color18;
          color: $color1;
          font-size:2.3rem;
          border-radius: 50%;
          width: 3.8rem;
          height: 3.8rem;

          &:hover {
            opacity: 0.8;
          }

          + .icon {
            margin-left: 1rem;
          }
        }
      }

      .picto-accessibilite {
        margin-top: 2rem;
        margin-bottom: 3rem;
        @media (max-width: $breakpoint-xs) {
          margin-left: auto;
          margin-right: auto;
        }

      }


    }

    .menu-footer {
      flex: 1 auto;
      max-width: calc(1199px - 10rem);
      @media (max-width: $breakpoint-xxl) {
        /* $breakpoint-lg $breakpoint-md $breakpoint-sm */
        max-width: calc(991px - 18rem);
      }

      ul {
        list-style-type: none;
        padding-left: 0;
      }

      .level1 {
        display: flex;
        justify-content: space-around;

        @media (max-width: $breakpoint-lg) {
          /*flex-direction: column;*/
          flex-wrap: wrap;
        }
        @media (max-width: $breakpoint-xs) {
          flex-direction: column;
        }

        > li {
          margin-left: 2rem;
          margin-right: 2rem;
          margin-bottom: 3rem;
          @media (max-width: $breakpoint-lg) {
            width: calc(50% - 4rem);
          }
          @media (max-width: $breakpoint-xs) {
            width: 100%;
          }
          > a,
          > .lien-off {
            color: $color8;
            font-weight: 600;

          }
        }

        .level2 {
          color: $color18;
          font-size: 1.3rem;
          margin-top: 1rem;
          line-height: 1.6;
          > li {
            > a,
            > .lien-off {
              color: $color18;
            }
          }
        }
      }
    }

  }

}

.sub-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 2rem;
  color: $color18;
  font-size: 1.2rem;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    border-top: solid .2rem $color3;
    padding-top: 1rem;

    @media (max-width: $breakpoint-md) {
      justify-content: center;
    }

    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      padding-left: 0;

      li a,
      li .lien-off {
        display: flex;
        color: $color18;
        font-size: 1.2rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding-top: .3rem;
        padding-bottom: .3rem;
        font-weight: normal;

        &:hover,
        &:focus {
          color: $color9;
        }
      }
    }

    .picto {
      display: flex;
      /*justify-content: space-between;*/
      align-items: center;
      .text {
        margin-right: 2rem;
      }
    }
  }

}

/* Signature Kyxar */
.kyxar {
  position: relative;
  z-index: 9;
  white-space: nowrap;
  right: 1rem;
  margin-top: 1rem;
  padding: 0;
  line-height: 2.5rem;
  font-size: 1rem;
  color: $color18;
  text-align: right;
  a,
  .lien-off {
    color: $color18;
    text-decoration: none;
    letter-spacing: .1rem;
    position: relative;
    .none {
      position: absolute;
      bottom: 3.8rem;
      right: 2.8rem;
      height: 0;
      overflow: hidden;
      color: $color18;
      span {
        display: inline-block;
        background-color: $color14;
        border-radius: .3rem;
        padding: .5rem 1rem;
        line-height: 1;
        font-weight: 300;
        font-size: 1rem;
      }
    }
    &:hover{
      color: $color9;
      .none {
        overflow: visible;
      }
    }
  }
}