/* Affichage date et tags */
.tag-label,
.tag-actu {
  display: inline-block;
  font-size: 1.2rem;
  padding: .2rem .5rem;
  color: $color1;
  background-color: $color17;
  border-radius: .3rem;
  margin: .2rem .1rem;
  &:hover,
  &:focus {
    background-color: $color9;
    color: $color1;
  }

  + .tag-label {
    margin-left: .2rem;
  }
}


.date-article {
  position: absolute;
  top: -.7rem;
  left: 1rem;
  right: auto;
  background-color: $color9;
  color: $color1;
  font-size: 0.9rem;
  border-radius: .3rem;
  padding: .2rem .6rem;
  z-index: 1;
}

/*** Liste des news et références ***/

.liste-articles article {
  margin-bottom: 6rem;

  .article-card {
    height: 100%;

    .link-article-img {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;


      .bloc-img {
        position: relative;

        .liste-tags {
          position: absolute;
          z-index: 1;
          right: 2rem;
          left: 1rem;
          bottom: 1.2rem;
          text-align: right;
        }

        figure {
          border-radius: 1rem;
          overflow: hidden;
          img {
            transition: all .3s ease-in-out;
          }
        }

        .date-article {
          top: -.7rem;
          left: 1rem;
          right: auto;
          background-color: $color9;
          color: $color1;
          font-size: 0.9rem;
          border-radius: .3rem;
        }
        .icon {
          position: absolute;
          z-index: 1;
          top: .6rem;
          right: -1.2rem;
          transition: all .2s ease-in-out;

          display: flex;
          justify-content: center;
          align-items: center;

          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          background-color: $color9;
          color: $color1;

          font-size: 1.6rem;

        }
      }



      .bloc-body {
        display: flex;
        flex-direction: column;

        transition: all .2s ease-in-out;
        padding-top: 1rem;
        height: 100%;

        .article-card-content {
          display: flex;
          flex-direction: column;


          min-height: 10rem;
          padding: .5rem 1rem 1rem;
          margin-top: 0;
          margin-bottom: auto;

          .title {
            font-size: 2rem;
            font-weight: normal;
            padding-bottom: 1rem;
            margin-top: 0;
            margin-bottom: auto;
            min-height: 5rem;

          }
          .desc {
            margin-top: auto;
            margin-bottom: auto;

            font-size: 1.4rem;
            color: $color18;
            @include line-clamp(3);
          }

        }
        .button-content {
          margin-top: auto;
          margin-bottom: 0;

          .button {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        }

        .liste-tags {
          position: absolute;
          left: .4rem;
          bottom: .4rem;

          .tag-label {
            font-size: 1.2rem;
            padding: .2rem .6rem;

            + .tag-label {
              margin-left: 0;
            }
          }
        }
      }

      &:hover,
      &:focus {
        .bloc-img {
          figure {
            img {
              transform: scale(1.2);
              filter: blur(0.1rem);
            }
          }
          .icon {
            transform: rotate(90deg) scale(1.1);
          }
        }

        .bloc-body {

          .button {
            color: $color1;
            background-color: $color9;
          }
        }
      }

    }

  }
}

/*** Article actif ***/
.article-content {
  margin-top: 6rem;
  margin-bottom: 6rem;

  .img-article-c {
    position: relative;

    .date-article {
      top: -.7rem;
      left: 1rem;
      right: auto;
    }

    .icon-item {
      position: absolute;
      z-index: 1;
      top: .6rem;
      right: -1.2rem;
      transition: all .2s ease-in-out;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: $color9;
      color: $color1;

      font-size: 1.6rem;

    }

    .slider-single-news {
      .slick-slide {
        margin-top: 1rem;
        margin-right: 1.5rem;
        > div {
          margin: 0;
          .slider-nav-container {
            .slider-nav-content {
              position: relative;
              figure {
                position: relative;
                border-radius: 1rem;
                overflow: hidden;
                img {
                  padding: 0;
                  transition: all .3s ease-in-out;
                }
              }
            }
          }
        }

        &:hover,
        &:focus {
          figure {
            img {
              transform: scale(1.2);
              filter: blur(0.1rem);
            }
          }
          .icon-item {
            transform: rotate(90deg) scale(1.1);
          }
        }

      }
    }

    .slider-nav-news {
      .slick-track {
        display: flex;
        align-items: center;

        figure {
          border-radius: 1rem;
          overflow: hidden;

          img {
            padding: 0;
            border: none;
            height: auto;
          }
        }

      }

      .slick-prev::before,
      .slick-next::before {
        font-size: 2rem;
      }
    }


  }

  .body-content {
    margin-right: 4rem;
    @media (max-width: $breakpoint-md) {
      margin-right: 0;
    }
    .date-article {
      position: static;
      color: $color9;
      background-color: transparent;
      font-size: 1.4rem;
      padding: 0;
      border-radius: initial;
    }
    .title {
      font-size: 4rem;
      text-align: left;
      font-weight: bold;
      @media (max-width: $breakpoint-md) {
        font-size: 3rem;
      }
    }
    .article-txt {
      margin-top: 2rem;
    }
  }
}
