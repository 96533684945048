#map,
#home-map {
  .leaflet-popup,
  .leaflet-popup .leaflet-popup-content-wrapper,
  .leaflet-popup .leaflet-popup-content{
    width: 38rem!important;
    height: auto;
  }
  .leaflet-popup .leaflet-popup-content-wrapper{
    background: #fff;
    border-radius: 1rem;
  }
  .leaflet-bar a:focus,
  .leaflet-bar a:hover,
  .leaflet-bar .lien-off:focus,
  .leaflet-bar .lien-off:hover {
    color: $color1;
    background-color: #fff;
  }
  .marker-cluster div {
    @include flex_center;
    background-image: url('../img/theme/map.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    span{
      font-size: 1.6rem;
      font-weight: 400;
      margin-top: -1rem;
    }
  }
  .popup_container{
    @include flex_between;
    .popup_img img{
      width: 16rem;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }
  }
  .popup_content{
    width: calc(100% - 16rem);
    color: $color18;
    padding: 2.5rem;
    .h4{
      margin-top: 0;
      text-align: left;
      color: $color9;
      font-size: 1.4rem;
      font-weight: 700;
    }
    .tel_popup{
      margin-top: 1rem;
    }
    a, .lien-off {
      position: relative;
      display: inline-block;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;
      color: $color18;
      letter-spacing: .1rem;
      margin-top: 1rem;
      .icon-plus{
        font-size: .8rem;
        margin-left: 1rem;
        margin-bottom: .2rem;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -.5rem;
        left: 0;
        display: block;
        height: .1rem;
        width: 100%;
        background-color: $color9;
        transition: width .3s;
      }
      &:hover::after{
        width: 0;
      }
    }
  }
  .popup_container.no-image .popup_content{
    width: 100%;
  }
  a.leaflet-popup-close-button,
  .lien-off.leaflet-popup-close-button {
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: .2rem solid #c3c3c3;
    border-radius: 50%;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: .9;
  }
}

@media (max-width: $breakpoint-xs) {
  #home-map .leaflet-popup,
  #map .leaflet-popup,
  #home-map .leaflet-popup .leaflet-popup-content, #home-map .leaflet-popup .leaflet-popup-content-wrapper,
  #map .leaflet-popup, #map .leaflet-popup .leaflet-popup-content, #map .leaflet-popup .leaflet-popup-content-wrapper{
    width: 18rem!important;
  }
  #home-map .popup_content,
  #map .popup_content{
    width: 100%;
    padding: 2rem 1rem;
  }
  #home-map .popup_container .popup_img,
  #map .popup_container .popup_img{
    display: none;
  }
  #home-map a.leaflet-popup-close-button,
  #map a.leaflet-popup-close-button,
  #home-map .lien-off.leaflet-popup-close-button,
  #map .lien-off.leaflet-popup-close-button{
    top: .5rem;
    right: .5rem;
  }
}