
.main-title {
  margin: 3rem 0;
}

.cms {
  h2, .h2,
  h3, .h3 {
    color: $color9;

    a {
      color: $color9;
    }
  }
}
.body_frise_historique {
  .cms {
    h2, .h2 {
      font-size: 2rem;
      @media (max-width: $breakpoint-md) {
        font-size: 1.6rem
      }
    }
  }
}
.cms-code-notre-equipe {
  .cms {
    h2, .h2 {
      color: $color8;
      font-size: 2.6rem;
      @media (max-width: $breakpoint-md) {
        font-size: 2rem
      }

      a {
        color: $color8;
      }
    }
    h3, .h3 {
      color: $color8;
      a {
        color: $color8;
      }
    }
    h4, .h4 {
      color: $color16;
      font-size: 1.7rem;
      margin-bottom: 1rem;
      @media (max-width: $breakpoint-md) {
        font-size: 1.4rem
      }
      a {
        color: $color16;
      }
    }
  }
}