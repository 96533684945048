
/* AVIS */

.bloc-avis {
  height: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 0;
  margin-right: 0;

  @media (max-width: $breakpoint-sm) {
    display:none;
  }
  > .link {
    height: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    color: $color8;
  }


  > .date {
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    color: $color9;
    font-size: 1.3rem;

  }


  .bloc-avis-content {
    .title {
      color: $color9;
      font-size: 1.3rem;
      font-weight: 600;
    }
    .liste-avis {
      margin-top:-0.8rem;
      .list-stars {
        .icon {
          font-size: 1.9rem;
          color: $color12;
          &.no-star {
            color: $color15;
          }
        }
      }
      .list-count {
        line-height: 1;
        font-size: 2rem;
        margin-left: 0.5rem;
        strong {
          font-size: 2.8rem;
        }

      }
    }
  }
}

/* Header & Footer */
.bloc-avis-header,
.bloc-avis-footer {
  &.bloc-avis {
    height: 100%;
    margin-left: auto;
    margin-right: auto;

  }
}
/* Home */
.bloc-avis-home {
  &.bloc-avis {
    background-color: $color1;
    color: $color8;
    padding: 1rem 2rem;
    margin-left: auto;
    margin-right: 0;
  }

}


.bloc-avis-page {

  .avis-list {
    list-style: none;
    padding: 0;

    .avis-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
      margin-left: 2rem;
      margin-right: 2rem;
      height: 100%;
      @media (max-width: $breakpoint-sm) {
        margin-left: 0;
        margin-right: 0;
      }

      > .title {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-style: italic;
        padding-bottom: 1rem;
        border-bottom: 2px solid $color13;

      }

      > .bloc-temoignage {
        /*flex: 1;*/
        display: flex;
        flex-direction: row;

        margin-top: auto;
        margin-bottom: auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
        @media (max-width: $breakpoint-sm) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .icon {
          color: $color17;
          font-size: 2rem;
          margin-top: -1rem;
          margin-right: 1rem;
          @media (max-width: $breakpoint-sm) {
            font-size: 1.6rem;
          }
        }
        .bloc-txt {

            font-size: 2rem;
            font-weight: bold;
            @media (max-width: $breakpoint-sm) {
              font-size: 1.6rem;
            }

        }
      }


      > .button-content {
        text-align: center;
        padding-top: 2rem;
        margin-top: auto;
        margin-bottom: 2rem;
        @media (max-width: $breakpoint-sm) {
          padding-top: 1rem;
          argin-bottom: 1rem;
        }

      }

    }
  }
  > .button-content {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
}


