.rassurances {
  margin-top: 4rem;
  margin-bottom: 3rem;

  .rassurance-card-c {
    @include flex-between;
    margin-right: 2rem;
    margin-left: 2rem;

    .rassurance-card {
      text-align: center;
      width: calc(20% - 1rem);

      .rassu-img-c {
        position: relative;
        display: inline-block;

        .icon {
          display: block;
          width: 8rem;
          height: 8rem;
          line-height: 1;
          font-size: 8rem;
          color: $color8;
          margin-right: auto;
          margin-left: auto;
          background-size: contain;
        }
      }

      .h4 {
        max-width: 18rem;
        min-height: 5.1rem;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 700;
        letter-spacing: .08rem;
        margin-top: 3rem;
        margin-right: auto;
        margin-left: auto;
        transition: color .3s ease-in-out;
      }

      &:hover .h4 {
        color: $color8;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .rassurances {
    margin-top: 6rem;

    .rassurance-card-c .rassurance-card .h4 .script-text {
      font-size: 2.2rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .page-home .rassurances {
    margin-top: 6rem;
  }
  .rassurances .rassurance-card-c {
    flex-wrap: wrap;
    justify-content: center;

    .rassurance-card {
      width: calc(100% / 3 - 1rem);
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .rassurances .rassurance-card-c .rassurance-card .rassu-img-c .icon {
    width: 6rem;
    height: 6rem;
    font-size: 6rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .page-home .rassurances {
    margin-top: 2rem;
  }
  .rassurances .rassurance-card-c .rassurance-card {
    width: 50%;

    .rassu-img-c {
      .icon {
        width: 5rem;
        height: 5rem;
        font-size: 5rem;
      }
    }

    .h4 {
      margin-top: 2rem;
    }
  }
}