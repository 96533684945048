.page-contact #contenu,
.page-devis #contenu {
  margin-top: 0;
  margin-bottom: 0;

  .container-contact {
    margin-top: 6rem;

    .title {
      margin-bottom: 4rem;
      text-align: left;
    }

    .h4{
      .icon{
        font-size: 4rem;
        margin-right: .8rem;
      }
    }

    .required-label {
      font-size: 1.2rem;
      margin-top: 1rem;

      .icon {
        font-size: 3rem;
        line-height: 1;
      }
    }

    button[type='submit'] {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }

    .bloc-contact {
      margin-bottom: 2rem;
      .button {
        font-size: 1.7rem;
        font-weight: bold;
        height: auto;
        padding: 0.8rem 1.5rem;
        .icon {
          font-size: 2rem;
        }
      }
    }

    #map {
      min-height: 25rem;
    }

  }

}

.class-form {
  margin-top: auto;
  margin-bottom: 0;
  margin-left: 3rem;
  width: 100%;


  padding: 4rem;
  background-color: $color16;
  color: $color1;
  border-radius: 2rem 2rem 0 0;
  box-shadow: 0 0 .5rem 0 rgba(0,0,0,.2);
  @media (max-width: $breakpoint-xs) {
    padding: 2rem;
    margin-left: 0;
  }
  .title {
    color: $color1;
  }
  a {
    color: $color1;
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .f-legal {
    font-size: 1.1rem;
  }
  .button-content {
    margin-top: 2rem;

    .button {
      height: 5.5rem;
      font-size: 1.6rem;
      .icon {
        font-size: 2.5rem;
        margin-right: .6rem;
        position: relative;
        left: 0;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .icon {
          left: -0.2rem;
          transform: scale(1.2);
        }
      }
    }
  }

  header {
    margin-bottom: 2rem;

    .title {
      color: $color1;
      margin-bottom: 2rem;
    }
  }

  .mini {
    font-size: 1.2rem;
  }

  .custom-form {
    margin-bottom: 4rem;
  }

}
.k-map-content {
  padding: 2rem;
  .title2 {
    color: $color9;
  }
}


/* form */
.page-contact,
.page-devis {
  .custom-form {
    .item-civilite {
      display: flex;
      align-items: center;
      margin-top:1.5rem;
      margin-bottom: 1.5rem;

      .label-title,
      .item-radio {
        margin-right: 2rem;
      }
    }
    .col-12 {
      .input-effect {
        max-width: none;
      }
    }

    .type-select {
      .select2 {
        position: relative;
        text-align: left;
        margin-left: auto;
        margin-bottom: 2rem;
        margin-right: auto;
        width: 100% !important;
        
        .selection {
          .select2-selection {
            display: block;
            height: 5.5rem;
            width: 100%;
            border: .1rem solid #bbb;
            border-radius: 3rem;
            padding: 1.5rem 1.5rem .7rem 1.2rem;
            z-index: 1;

            .select2-selection__arrow {
              height: 26px;
              width: 20px;

              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: .5rem;
            }
          }

        }

        &.select2-container--open {
          .selection {
            .select2-selection {
              border-radius: 3rem 3rem 0 0;
            }

          }

        }

      }
    }

    .item-message {
      label {
        margin-bottom: 0.5rem;
      }
    }

    /* devis */
    .item-rapidement {
      .label-title {
        display: none;
      }
      label { font-weight: normal !important; }
    }
    .item-telephone,
    .item-email,
    .item-adresse {
      &::before {
        content: '';
        display: flex;
        margin-bottom: 1rem;
        font-weight: bold;
        min-height: 2.5rem;
      }
    }
    .item-telephone {
      &::before {
        content: 'Pour vous contacter';
        font-weight: bold;
      }
    }
    .item-rapidement {
      &::after {
        content: '';
        display: flex;
        margin: 2rem 0;
        border-bottom: 1px solid $color1;
      }
    }
    .item-hidden-col2 {
      display: flex;
      flex-direction: column;
      &::before {
        content: 'Votre projet';
        display: flex;
        font-weight: bold;
      }
      &::after {
        content: 'Veuillez-nous indiquer la zone de votre projet';
        display: flex;
        font-weight: 600;
        margin-top: 1.8rem;
        margin-bottom: auto;
      }

    }


  }
}


.page-contact,
.page-devis {
  .select2-dropdown {
    margin-top: -.1rem;
  }
}


.liste-numero {
  list-style: none;
  padding-left: 0;
  counter-reset: number;

  li {
    /*display: flex;
    flex-direction: row;
    align-items: center;*/
    margin: 1rem 0;
    &::before {
      counter-increment: number;
      content: counters(number,"");

      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 3.3rem;
      height: 3.3rem;
      background-color: $color9;
      color: $color1;
      font-weight: bold;
      border-radius: 50%;
      margin-right: 1rem;

    }
  }
}