#realisations {
  .realisation_block {
    .slick-prev {
      position: absolute;
      top: -20px;
      left: calc(45% - 30px) !important;
      /*left: -34vw !important;*/
      transform: none;
      background: #fff;
    }
    .slick-next {
      position: absolute;
      top: -20px;
      right: calc(45% - 30px) !important;
      /*right: -1vw !important;*/
      transform: none;
      background: #fff;
    }
  }
}

.home-content {

}

// var cercle activité
$dim-cercle: 16rem;

.noslideshow + .contenu-home {
  .bloc-activites {
    margin-top: -9rem;
    padding: 3rem;
    @media (max-width: $breakpoint-lg) {
      margin-top: -6rem;
    }
    @media (max-width: $breakpoint-md) {
      flex-direction: row;
      margin-top: -2rem;
    }
  }
}

.bloc-activites {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: -9.4rem;


  @media (max-width: $breakpoint-md) {
    flex-direction: row;
    margin-top: -2rem;
  }


  .item {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    max-width: 46rem;
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;

    @media (max-width: $breakpoint-xxxl) {
      max-width: 40rem;
    }
    @media (max-width: $breakpoint-xxl) {
      max-width: 36rem;
    }
    @media (max-width: $breakpoint-xl) {
      max-width: 30rem;
    }
    @media (max-width: $breakpoint-lg) {
      max-width: 46rem;
    }


    @media (max-width: $breakpoint-md) {
      &:first-child {
        width:100%;
      }
    }

    .link,
    .no-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;

      text-align: center;

    }
    .link-img-content,
    .no-link-img-content {
      height: $dim-cercle;
    }

    .img-content {
      position: relative;
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: $dim-cercle;
      height: $dim-cercle;
      border-radius: 50%;
      background-color: $color1;
      margin-top: 0;
      margin-bottom: 0;

      @media (max-width: $breakpoint-xs) {
        transform: scale(0.8);
      }


      .img-pattern {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        left: -1rem;
        top: -1rem;

        overflow: hidden;
        width: calc(100% + 2rem);
        height: calc(50% + 2rem);
        /*background-color: rgba(100,0,0,0.3);*/


        &:before {
          content:'';
          position: absolute;
          left: 1rem;
          top: 1rem;

          width: $dim-cercle;
          height: $dim-cercle;

          border-radius: 50%;
          box-shadow: 0 0 .6rem .1rem rgba(0,0,0,0.2);
          /*background-color: rgba(0,100,0,0.3);*/
        }

      }
      img {
        position: relative;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
      }

    }
    .item-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-top: auto;
      margin-bottom: 0;
      flex: 1;

      .h1, .h2, .h3 {
        max-width: 40rem;
        @media (max-width: $breakpoint-xxl) {
          max-width: 20em;
        }
      }

      .h1 {
        text-transform: none;
        font-size: 2rem;
        margin: 1rem 0;
        font-weight: bold;
      }
      .h3 {
        font-size: 1.7rem;
        font-weight: normal;
        margin: 1rem 0 2rem 0;
        text-align: center;
      }
      .kicon-01 {
        position: relative;
        top: 0;
        color: $color11;
        font-size: 1.2rem;
        transition: all 0.3s ease-in-out;
      }
      .button {
        margin-top: auto;
        margin-bottom: 0;
      }

      .menu-level {
        padding: 0;
        margin: 0 0 3rem 0;
        list-style: none;
        width: 100%;
        .menu-item {
          a {
            position: relative;
            justify-content: start;
            font-size: 1.4rem;
            border-bottom: .1px solid $color13;
            padding: 1rem;

            &:hover,
            &:focus {
              color: $color16;
              .icon {
                right: 0;
              }
            }
            .icon {
              position: absolute;
              right: 1rem;
              top: 50%;
              transform: translateY(-50%);
              color: $color11;
              font-size: 1rem;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }


    }

    &:hover {
      .link-img-content {
        .img-content {
          &.img-menu-ascenseurs,
          &.img-menu-plateformes,
          &.img-menu-monte-escaliers {
            img {
              top: -12px;
            }
          }
          &.img-menu-monte-escaliers {
            img {
              left: -12px;
            }
          }

        }
      }


      .item-content {
        .kicon-01 {
          top: 12px;
        }
      }


    }



  }

}

.bloc-rappel {
  background-color: $color14;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: $breakpoint-md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .item-img {
      margin-right: 8rem;

      @media (max-width: $breakpoint-md) {
        margin-right: 4rem;
      }
      @media (max-width: $breakpoint-sm) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
      figure {
        border-radius: 50%;
        overflow: hidden;
        /*box-shadow: 0 0 .5rem 0 rgba(0,0,0,0.15);*/

      }
    }
    .item-bloc {
      flex: 1 auto;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .item-1 {
        color: $color18;
        width: 100%;
        .h2 {
          text-transform: none;
          font-weight: normal;
        }
      }
      .item-2 {
        width: 100%;
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        form {
          width: 100%;
        }
        .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: start;
          justify-content: space-between;
          .col-auto {
            flex: 1 auto;
            .input-effect {
              margin-left: 0;
            }
            &.col-button {

              .input-effect {
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                max-width: none;
              }
              @media (max-width: $breakpoint-md) {
                width: 100%;
              }
            }
          }
        }
        .button {
          height: 5.5rem;
          font-size: 1.6rem;
          .icon {
            font-size: 2.5rem;
            position: relative;
            left: 0;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            .icon {
              left: -0.2rem;
              transform: scale(1.2);
            }
          }
        }

        /* Form généré */
        .bloc-home-contact-aide {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: start;
          justify-content: space-between;
          @media (max-width: $breakpoint-lg) {
            flex-direction: column;
          }

          /*> div {
            flex: 1 auto;
            @media (max-width: $breakpoint-lg) {
              width: 100%;
            }
          }*/
            > .row {
              > div {
                flex: 1 auto;
                width: auto;
                .input-effect {
                  margin-left: 0;
                  .item-telephone {
                    &::before {
                      display:none;
                    }
                  }
                }
              }
            }
            .col-button {
              max-width: 39rem;
              width: 100%;
              @media (max-width: $breakpoint-lg) {
                max-width: none;
              }
              .input-effect {
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                max-width: none;

              }
            }

          }


      }
      .item-3 {
        color: $color18;
        width: 100%;
      }

    }

  }


  &.bloc-rappel-center {
    .container {
      /*flex-wrap: wrap;*/
      padding-top: 8rem;


      @media (max-width: $breakpoint-sm) {
        padding-top: 2rem;
      }

      .item-img {
        margin-right: 4rem;
        margin-left: auto;
        @media (max-width: $breakpoint-sm) {
          margin-right: auto;
          margin-bottom: 0;
        }
      }

      .item-bloc {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-width: 80rem;
        margin-right: auto;
        margin-left: 0;

        .item-title {
          color: $color18;
          width: 100%;
          margin-left: 3rem;
          margin-top: -8rem;
          margin-bottom: 3rem;
          @media (max-width: $breakpoint-sm) {
            margin-left: 0;
            margin-top: 1rem;
            margin-bottom: 0;
            text-align: center;
          }

          .h2 {
            text-transform: none;
            font-weight: normal;
          }

        }


        .item-contact {
          width: auto;
          margin-left: 0;
          margin-right: 4rem;
          flex: 1;

          @media (max-width: $breakpoint-md) {
            margin-right: 2rem;
            flex: initial;
          }
          @media (max-width: $breakpoint-sm) {
            margin-left: auto;
          }

          .item-nom {
            color: $color16;
            font-size: 2.8rem;
            font-weight: bold;
            @media (max-width: $breakpoint-md) {
              font-size: 2.2rem;
            }
            @media (max-width: $breakpoint-sm) {
              font-size: 1.8rem;
            }
          }
          .item-poste {
            font-size: 1.8rem;
            @media (max-width: $breakpoint-md) {
              font-size: 1.4rem;
            }
            @media (max-width: $breakpoint-sm) {
              font-size: 1rem;
            }
          }
        }

        .item-content-button {
          width: auto;
          flex: initial;
          margin-left: 0;
          margin-right: auto;


          .menu-access-fiche {
            margin-right: 1rem;
            @media (max-width: $breakpoint-sm) {
              margin-right: 0;
            }

            .menu-acces {
              .level1 {
                display: flex;

                position: relative;
                margin: 0;
                padding: 0;
                list-style: none;
                font-size: 1.6rem;
                @media (max-width: $breakpoint-sm) {
                  font-size: 1.4rem;
                }

                .acces-menu-item {
                  position: relative;
                  margin: 0 1rem;
                  @media (max-width: $breakpoint-sm) {
                    margin: 0 0.3rem;
                  }

                  &:last-child {
                    margin-right: 0;
                  }

                  a,
                  .lien-off {
                    color: $color8;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    height: 100%;

                    [class^="icon-"],
                    [class*=" icon-"] {
                      display: flex;
                      margin-right: 0.8rem;
                      font-size: 2.6rem;

                      position: relative;
                      left: 0;
                      transition: all 0.3s ease-in-out;

                      @media (max-width: $breakpoint-sm) {
                        margin-right: 0;
                        font-size: 2rem !important;
                      }
                    }

                    &:hover,
                    &:focus {
                      color: $color9;
                      [class^="icon-"],
                      [class*=" icon-"] {
                        left: -0.1rem;
                        transform: scale(1.2);
                      }
                    }

                    &.item-devis,
                    &.item-mail {
                      border-radius: 3rem;
                      padding: 1.5rem 3rem;
                      @media (max-width: $breakpoint-sm) {
                        padding: 1rem 1rem;
                      }

                    }

                    &.item-devis {
                      background-color: $color10;
                      color: $color1;

                      &:hover,
                      &:focus {
                        background-color: $color1;
                        color: $color10;
                        box-shadow: inset 0 0 0 1px $color10;
                      }
                    }

                    &.item-mail {
                      background-color: $color9;
                      color: $color1;

                      &:hover,
                      &:focus {
                        background-color: $color1;
                        color: $color9;
                        box-shadow: inset 0 0 0 1px $color9;
                      }

                      [class^="icon-"],
                      [class*=" icon-"] {
                        font-size: 2.5rem;
                      }
                    }

                    @media (max-width: $breakpoint-md) {
                      .text {
                        display: none;
                      }
                    }
                  }

                  .level2 {
                    max-height: 0;
                    transition: all .2s ease-in-out;
                    overflow: hidden;
                    position: absolute;
                    z-index: 5;
                    top: 100%;
                    right: 0;
                    background-color: $color14;
                    min-width: 18rem;
                    border-radius: 2rem 2rem 1rem 1rem;


                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                      padding: 0.5rem 1rem;
                      font-size: 80%;
                    }

                  }

                  &:hover {
                    .level2 {
                      max-height: 20rem;
                      padding: 1rem 0;
                    }
                  }

                }


              }
            }
          }
        }
      }
    }
  }



}

.bloc-choisir {
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: $breakpoint-md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    max-width: $breakpoint-md;

    /*@media (max-width: $breakpoint-xxl) {
      max-width: $breakpoint-sm;
    }*/

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .item-1 {
      color: $color18;
      max-width: 54rem;
      margin: auto auto 3rem auto;
      text-align: center;

      @media (max-width: $breakpoint-sm) {
        max-width: none;
      }
      .h2 {
        text-transform: none;
        font-weight: normal;
      }
    }

    .item-2 {
      .item {
        margin-bottom: 2rem;

        .link-item-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;


          .img-content {
            width: 6rem;
            height: 6rem;
            margin-left:3rem;
            margin-right:3rem;
            margin-top: 0;
            margin-bottom: auto;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;

            }

          }
          .item-content {
            flex: 1;
            color: $color18;
            font-size: 1.4rem;
            .title {
              font-size: 2.1rem;
              margin-bottom: 1rem;
              font-weight: bold;
            }
          }
        }
      }

    }

    .item3 {
      margin-top: 3rem;

    }
  }

}


.bloc-locator {

  .home-map-content {
    position: relative;
    padding: 0;
    .home-map {
      position: relative;
      height: 100%;

      .leaflet-tile-container img{
        /*filter: grayscale(100);*/
      }
    }
  }
  .bloc-locator-content {
    background-color: $color16;
    color: $color1;
    font-size: 1.6rem;
    padding: 5rem;
    @media (max-width: $breakpoint-sm) {
      padding: 3rem;
    }

    .title {
      color: $color1;
      font-size: 3.2rem;
      margin-bottom: 1.5rem;
      text-transform: none;
      font-weight: normal;
    }

    .zone-select {
      margin-top: 3rem;



      .item-zone {
        &,
        & input {
          height: 5.5rem;
          max-width: 35rem;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        margin-bottom: 2rem;
        background-color: $color1;
        border-radius: 4rem;
        padding: 0 2rem;

        > .icon {
          display: flex;
          color: $color15;
          font-size: 3rem;
          order: 1;
          transition: all 0.3s ease-in-out;
        }
        > .bt-search {
          order: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          /*position: absolute;
          right: 1.4rem;
          top: 50%;
          transform: translateY(-50%);*/
          width: 4.3rem;
          height: 4.3rem;
          border-radius: 50%;
          text-align: center;
          padding: 0;
          margin-right: -1rem;
          > .icon {
            display: flex;
            font-size: 2rem;
          }

          &:hover,
          &:focus {
            color: #fff;
            background-color: $color10;
            border-color: $color10;
          }
        }

        .input-effect,
        .checkbox-container {
          max-width: none;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: auto;
          margin-top: auto;

          input {
            border: none;
            & ~ label {
              background-color: transparent;
              color: $color15;
            }
          }


        }
        .input-container {
          order: 2;
          flex: 1;
          &:hover {
            & + .icon {
              color: $color11;
            }
          }
          input {

            &.valid {
              box-shadow: none;
            }
          }
        }

        .checkbox-container {
          position: relative;
          input {
            & ~ label {
              color: $color18;
              font-weight: normal;
              margin-left: 0;
              .picto {
                margin-right: 1rem;
              }

            }
            &:checked {
              + label {
                font-weight: bold;
              }
            }
          }

        }

      }
    }

  }

}


.bloc-temoignage {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8rem;

  @media (max-width: $breakpoint-sm) {
    padding-top: 6rem;
  }


  .container-1 {
    max-width: $breakpoint-md;
    color: $color1;

    .item-1 {
      text-align: center;
      margin-bottom: 3rem;
      .title {
        color: $color1;
        text-align: center;
      }
    }

    .item-2 {
      .home-temoignage {
        .slide {
          padding-top:1rem;

          .link-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .bloc-img {
              width: 15rem;
              height: 15rem;
              margin-right: 4rem;
              border-radius: 50%;
              overflow: hidden;
              box-shadow: 0 0 .5rem 0 rgba(0,0,0,.2);
            }

            .bloc-content {
              flex: 1;
              display: flex;
              flex-direction: row;

              color: $color1;

              .icon {
                color: $color17;
                font-size: 2rem;
                margin-top: -1rem;
                margin-right: 1rem;
                @media (max-width: $breakpoint-sm) {
                  font-size: 1.6rem;
                }
              }
              .bloc-txt {
                .txt-1 {
                  font-size: 2rem;
                  font-weight: bold;
                  @media (max-width: $breakpoint-sm) {
                    font-size: 1.6rem;
                  }

                }
                .txt-2 {
                  font-size: 1.7rem;
                  font-weight: normal;
                  margin-top: 2rem;
                  @media (max-width: $breakpoint-sm) {
                    font-size: 1.4rem;
                  }
                }
              }
            }

          }
        }

        .slick-prev {
          left: -5.5rem;
        }
        .slick-next {
          right: -5.5rem;
        }
        .slick-next::before,
        .slick-prev::before {
          color: $color1;
        }
        .slick-dots {
          bottom: -2.5rem;
          text-align: center;

          li {
            button {
              &::before {
                background-color: $color1;
              }
            }
          }

        }

      }
    }

  }

  .container-2 {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;

  }

}



.bloc-news {
  background-color: $color14;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media (max-width: $breakpoint-sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .container {

    .item-1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 2rem;
      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
      }

      .title {
          .mini {
            display: block;
            font-size: 1.7rem;
            margin-top: 1rem;
            color: $color18;
          }
      }
      .button {
        padding: 1.5rem 3rem;
        @media (max-width: $breakpoint-xs) {
          margin-top: 1rem;
        }
      }
    }

    .item-2 {
      .slider-actus {
        .slide {
          .item-news {
            display: flex;
            flex-direction: column;
            margin-top: .8rem;

            .news-link {
              display: flex;
              flex-direction: row;

              .bloc-img {
                position: relative;
                figure {
                  border-radius: 1rem;
                  overflow: hidden;
                  img {
                    transition: all .3s ease-in-out;
                  }

                }
                .date-article {
                  top: -.7rem;
                  left: 1rem;
                  right: auto;
                  background-color: $color9;
                  color: $color1;
                  font-size: 0.9rem;
                  border-radius: .3rem;
                }
                .icon {
                  position: absolute;
                  z-index: 1;
                  top: .6rem;
                  right: -1.5rem;
                  transition: all .2s ease-in-out;

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  width: 3.5rem;
                  height: 3.5rem;
                  border-radius: 50%;
                  background-color: $color9;
                  color: $color1;

                  font-size: 1.6rem;

                }
              }

              .bloc-body {
                transition: all .2s ease-in-out;
                padding-left: 3rem;
                padding-top: 2rem;
                .title {
                  font-size: 2rem;
                  font-weight: bold;
                  margin-bottom: 1rem;
                }
                .desc {
                  font-size: 1.4rem;
                  color: $color18;
                }
                .n-link {
                  margin-top: 1rem;
                  > span {
                    font-size: 1.4rem;
                    color: $color9;
                    font-weight: bold;

                    .icon {
                      font-size: 1rem;
                    }
                  }
                }
              }

              &:hover,
              &:focus {
                .bloc-img {
                  figure {
                    img {
                      transform: scale(1.2);
                      filter: blur(0.1rem);
                    }
                  }
                  .icon {
                    transform: rotate(90deg) scale(1.1);
                  }
                }

                .bloc-body {
                  padding-left: 3.5rem;
                  @media (max-width: $breakpoint-sm) {
                    padding-left: 3rem;
                  }
                  .n-link {
                    > span {
                      color: $color8;
                    }
                  }
                }
              }


            }
          }
        }

      }
    }
  }
}



.bloc-faq {
  padding-top: 8rem;
  padding-bottom: 0;

  @media (max-width: $breakpoint-md) {
    padding-top: 6rem;
  }

  .container {

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .item-1 {
      display: flex;
      flex-direction: column;

      color: $color1;

      .title {
        margin-top: 0;
        margin-bottom: auto;
        padding-bottom: 2rem;
      }
      .class-form {
        margin-top: auto;
        margin-bottom: 0;
        margin-left: 0;

        padding: 4rem;
        background-color: $color16;
        border-radius: 2rem 2rem 0 0;
        box-shadow: 0 0 .5rem 0 rgba(0,0,0,.2);
        @media (max-width: $breakpoint-xs) {
          padding: 2rem;
        }

        a {
          color: $color1;
          text-decoration: underline;
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
        .f-legal {
          font-size: 1.1rem;
        }
        .button-content {
          margin-top: 4rem;

          .button {
            height: 5.5rem;
            font-size: 1.6rem;
            .icon {
              font-size: 2.5rem;
              margin-right: .6rem;
              position: relative;
              left: 0;
              transition: all 0.3s ease-in-out;
            }
            &:hover {
              .icon {
                left: -0.2rem;
                transform: scale(1.2);
              }
            }
          }
        }


        header {
          margin-bottom: 2rem;

          .title {
            color: $color1;
            margin-bottom: 2rem;
          }
        }

      }
    }
    .item-2 {
      .liste-faq {
        margin-left: 2rem;
        @media (max-width: $breakpoint-sm) {
          margin-left: 0;
        }

       /* .item-faq {
          border-top: 1px solid $color13;
          padding-top: 2rem;
          padding-bottom: 2rem;

          .button-content {
            margin-top: 2rem;
          }
        }*/
      }

    }

  }
}

/*

.entreprise_locator{
  position: relative;
  margin-top: 0;
  margin-bottom: 8rem;
  .entreprise_locator_desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    background-color: $color2;
    padding: 5rem 7rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    //@include box-shadow(rgba(85, 85, 85, .5));
    .h2{
      line-height: .8;
      color: #fff;
      margin-right: auto;
      margin-top: -1.3rem;
      margin-bottom: 1.6rem;
    }
    p{
      font-size: 1.8rem;
      font-weight: 500;
      margin-top: .8rem;
      line-height: 1.2;
      color: #fff;
    }
    .pre_form{
      color: #fff;
      font-size: 1.8rem;
      font-weight: 500;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
    .search_ateliers{
      margin-left: -1rem;
      margin-right: -2rem;
      flex-direction: column;
      .input-c{
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        .input-item.input_cp_ville{
          width: 100%;
          margin-bottom: 2rem;
        }
        .select2-c{
          width: calc(50% - 1rem);
          .input-item{
            margin-left: 0;
          }
        }
      }
      .button{
        @include flex_center;
        font-size: 1.5rem;
        padding: 1.2rem 2rem;
        margin-top: 2rem;
        cursor: pointer;
      }
    }
  }
  #home_map{
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    //@include box-shadow(rgba(85, 85, 85, .5));
    .leaflet-tile-container img{
      filter: grayscale(100);
    }
  }
}
*/
